<template>
  <div class="category">
    <span class="category__count">
      {{categories.length}}
    </span>

    <Tooltip
      v-if="categories.length"
      icon="info"
      class="text-graphite-800 ml-1"
    >
      <ul :key="id" v-for="{id, name} in categories">
        <li class="category__item">{{ name }}</li>
      </ul>
    </Tooltip>
  </div>
</template>

<script>
import Tooltip from "@/components/ui/Tooltip";

export default {
  components: {Tooltip},

  props: {
    categories: {
      type: Array,
      default: () => [],
    }
  },
};
</script>

<style scoped>
.category {
  @apply flex;
}

.category__count {
  @apply inline-block;
  min-width: 1.5rem;
}

.category__item {
  @apply text-2sm text-black font-inter;

  &:before {
    content: '-';
    @apply mr-1;
  }
}
</style>
