<template>
  <div class="rentable-items">
    <div class="rentable-items__count">
      <amount-input :value="itemsCount" amountClass="mr-1" precision="0" readOnly /> items
    </div>

    <InfiniteTable
      :dataProvider="$riDataProvider"
      resource="rentableItems"
      resourceLabel="rentable items"
      :columns="columns"
      @click="handleRowClick"
      @initialized="onTableInitialized"
    >
      <template v-slot:column:imgUrl>
        <img class="rentable-items__img" :src="require('@/assets/modules/NA.png')" />
      </template>

      <template v-slot:column:name="{ value }">
        <text-trimmer :text="value" />
      </template>

      <template v-slot:column:locationsChain="{ value }">
        <span v-if="!value.length">-</span>
        <DataChain v-else :data="value" />
      </template>

      <template v-slot:column:categories="{ value }">
        <RICategories :categories="value" />
      </template>

      <template v-slot:column:itemsCount="{ value: { available, total } }">
        <div v-if="available" class="flex items-center">
          <AvailabilityIndicator
            class="rentable-items__icon"
            :value="1 - available / total"
          />
          <amount-input :value="available" precision="0" readOnly />
        </div>
        <div v-else class="rentable-items__not-available">Not available</div>
      </template>

      <template v-slot:column:timeslot="{ value: { rate, unit } }">
        <amount-input :value="rate" prefix="$" readOnly />
         <div class="rentable-items__unit">/{{ unit.toLowerCase() }}</div>
      </template>
    </InfiniteTable>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import InfiniteTable from "@/components/list/InfiniteTable";
import AmountInput from "@/components/ui/AmountInput";
import TextTrimmer from "@/components/ui/TextTrimmer";
import RICategories from "@/components/ri/RICategories";
import DataChain from "@/components/ui/DataChain";
import AvailabilityIndicator from "@/components/ui/AvailabilityIndicator";

const columns = [
  {
    name: "imgUrl",
    title: "image",
    class: "w-1/12",
  },
  {
    name: "name",
    title: "rentable item",
    class: "w-4/12",
  },
  {
    name: "locationsChain",
    title: "location",
    class: "w-3/12",
  },
  {
    name: "categories",
    title: "categories",
    class: "w-1/12 flex justify-center",
  },
  {
    name: "itemsCount",
    title: "available now",
    class: "min-w-24 w-1/12",
  },
  {
    name: "timeslot",
    title: "price",
    class: "text-right w-2/12",
  },
];

export default {
  components: {
    AvailabilityIndicator,
    InfiniteTable,
    AmountInput,
    TextTrimmer,
    RICategories,
    DataChain,
  },

  mixins: [ModalNavigation],

  props: {
    columns: {
      type: Array,
      default: () => columns,
    },
  },

  data: function(){
    return {
      itemsCount: 0,
    };
  },

  methods: {
    onTableInitialized(items, count){
      this.itemsCount = count;
    },

    handleRowClick(value){
      this.$router.push({
        name: 'ri.reservations',
        params: {
          rentableItemId: value.id,
        },
      });
    },
  },
};
</script>

<style scoped>
  .rentable-items {
    @apply flex flex-col h-full;
  }

  .rentable-items__not-available {
    @apply text-graphite-900 text-sm font-inter;
  }

  .rentable-items__icon {
    @apply mr-2;
  }

  .rentable-items__count {
    @apply flex font-frank font-bold text-sm text-black mb-3 mr-2;
  }

  .rentable-items__unit {
    @apply font-inter text-sm text-graphite-900;
  }

  .rentable-items__img {
    width: 54px;
    min-width: 54px;
  }
</style>
