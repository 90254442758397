<template>
  <div class="time-picker">
    <div v-if="!closed" class="flex items-center">
      <div class="w-12">
        <Dropdown
          :options="hoursOptions"
          :value="value.hours"
          @input="(val) => handleInput('hours', val)"
          :noClear="true"
          :searchable="false"
          :disabled="disabled"
          placeholder=""
          no-controls
          centered
        />
      </div>

      <span class="text-2sm font-inter mx-2">:</span>

      <div class="w-12">
        <Dropdown
          :options="minutesOptions"
          :value="value.minutes"
          @input="(val) => handleInput('minutes', val)"
          :noClear="true"
          :searchable="false"
          :disabled="disabled"
          placeholder=""
          no-controls
          centered
        />
      </div>

      <ToggleButtonsInput
        :value="value.clock"
        :options="clockOptions"
        @change="(val) => handleInput('clock', val)"
        size="large"
        class="ml-2"
        buttonClass="px-3"
        :disabled="disabled"
      />
    </div>

    <div v-else class="time-picker__closed">closed</div>
  </div>
</template>

<script>
import Dropdown from "@/components/ui/Dropdown";
import ToggleButtonsInput from "@/components/ui/ToggleButtonsInput";

const hours = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];
const minutes = ["00", "15", "30", "45"];
const clockOptions = [
  { key: "am", value: "am" },
  { key: "pm", value: "pm" },
];

export default {
  props: {
    /**
     * TimePicker value
     */
    value: {
      type: Object,
      required: true,
      default() {
        return {
          hours: "00",
          minutes: "00",
          clock: "am",
        };
      },
    },
    /**
     * Input's `name` attribute
     */
    name: {
      type: String,
    },
    /**
     * Whether input is readonly
     */
    readOnly: {
      type: Boolean,
    },
    /**
     * Disabled state
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Unavailable state
     */
    closed: {
      type: Boolean,
    },
    /**
     * Available hours array
     */
    hours: {
      type: Array,
      default: () => hours,
    },
    /**
     * Available minutes array
     */
    minutes: {
      type: Array,
      default: () => minutes,
    },
    /**
     * Available clock array
     */
    clockOptions: {
      type: Array,
      default: () => clockOptions,
    },
  },

  components: {
    Dropdown,
    ToggleButtonsInput,
  },

  emits: ["time-picked"],

  computed: {
    hoursOptions() {
      return this.hours.map((hour) => ({ key: hour, value: hour }));
    },
    minutesOptions() {
      return this.minutes.map((minute) => ({ key: minute, value: minute }));
    },
  },

  methods: {
    handleInput(fieldname, value) {
      const time = {
        hours: this.value.hours,
        minutes: this.value.minutes,
        clock: this.value.clock,
        [fieldname]: value,
      };

      this.$emit("time-picked", time);
    },
  },
};
</script>

<style scoped>
.time-picker__closed {
  @apply flex items-center justify-center bg-gray-200 font-frank text-2sm font-medium text-graphite-700 h-10 px-4;
  min-width: 13.3rem;
  width: 214px;
}

.time-picker__clock {
  @apply border rounded-sm h-10 px-3 text-2sm font-frank font-medium border-active-200 bg-highlight-200 text-highlight-400;
}

.time-picker__clock--active {
  @apply border-active-500 bg-highlight-200 text-active-500;
}
</style>
