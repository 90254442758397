<template>
  <InfiniteTable
    class="reservations-list"
    :class="{'reservations-list--with-toolbar': $slots.toolbar}"
    :list-classes="{
      tableHeaderClass: 'head reservations-list__table-header',
    }"
    :dataProvider="$riDataProvider"
    resource="reservations"
    resourceLabel="reservations"
    :additionalRequestParams="{ riId: rentableItem.id }"
    :columns="columns"
    :filters="apiFilters"
    @click="handleRowClick"
    @initialized="handleTableInitialized"
  >
    <template #header>
      <slot name="header" />

      <ReservationsToolbar>
        <template #right v-if="$slots.toolbar">
          <slot name="toolbar" />
        </template>

        <template #filters>
          <FilterBlock
            ref="filter"
            :filters="[]"
            :values="{}"
            searchPlaceholderText="Search by resident, unit, asset"
            @change="setFilterValues"
            controlsClass="w-1/3"
          />
        </template>
      </ReservationsToolbar>

      <div class="reservations-list__heading">
        <amount-input
          :value="reservationsCount"
          amountClass="mr-1"
          precision="0"
          readOnly
        />
        {{ $tc('ri.reservation', reservationsCount) }}
      </div>
    </template>

    <template #column:profile="{ value }">
      <div>
        <span>Unit <span v-html="highlight(value.unitName)" />,</span>
        <span v-if="value.unitId !== value.apartmentId"> Apartment {{value.apartmentName}},</span>
        <span> Building {{value.buildingName}}</span>
        <div v-html="highlight(`${value.firstName} ${value.lastName}`)"/>
      </div>
    </template>

    <template #column:price="{ value }">
      <div>{{ value.qty }}</div>
    </template>

    <template #column:asset="{ value }">
        <div v-html="highlight(value.name)"></div>
    </template>

    <template #column:start="{ item }">
      <div>
        <div>
          <CommunityDateTimeDisplay :input="item.start" format="ll, hh:mm a" />
        </div>
        <div>
          <CommunityDateTimeDisplay :input="item.end" format="ll, hh:mm a" />
        </div>
      </div>
    </template>

    <template #column:status="{ value }">
      <ReservationStatus :status="value" />
    </template>

    <template #column:createdAt="{ item }">
      <div class="text-graphite-900">
        <div>
          <CommunityDateTimeDisplay
            :input="item.createdAt"
            format="ll, hh:mm a"
          />
        </div>
        <div>By {{ item.createdBy }}</div>
      </div>
    </template>
  </InfiniteTable>
</template>

<script>
import { isEqual, words } from 'lodash-es';
import { prepareWordsModeHighlighter } from "@/utils/highlighting";
import InfiniteTable from '@/components/list/InfiniteTable';
import CommunityDateTimeDisplay from '@/components/ui/CommunityDateTimeDisplay';
import ReservationsToolbar from '@/components/ri/reservations/ReservationsToolbar';
import ReservationStatus from '@/components/ri/ReservationStatus';
import AmountInput from '@/components/ui/AmountInput';
import FilterBlock from '@/components/list/FilterBlock';

export default {
  components: {
    InfiniteTable,
    CommunityDateTimeDisplay,
    ReservationsToolbar,
    ReservationStatus,
    AmountInput,
    FilterBlock,
  },

  props: {
    rentableItem: {
      required: true,
    },
  },

  emits: ['selectItem'],

  data() {
    return {
      reservationsCount: 0,
      filterValues: {},
    };
  },

  computed: {
    columns() {
      if (this.rentableItem.isFungible) {
        return [
          {
            name: 'profile',
            title: 'unit & resident',
            class: 'self-start w-3/12',
          },
          {
            name: 'price',
            title: 'qty',
            class: 'self-start w-1/12',
          },
          {
            name: 'start',
            title: 'start & end date',
            class: 'self-start w-3/12',
          },
          {
            name: 'status',
            title: 'status',
            class: 'self-start w-2/12',
          },
          {
            name: 'createdAt',
            title: 'creation date',
            class: 'self-start w-3/12',
          },
        ];
      }

      return [
        {
          name: 'profile',
          title: 'unit & resident',
          class: 'self-start col__21',
        },
        {
          name: 'asset',
          title: 'asset',
          class: 'self-start col__21',
        },
        {
          name: 'start',
          title: 'start & end date',
          class: 'self-start col__21',
        },
        {
          name: 'status',
          title: 'status',
          class: 'self-start col__16',
        },
        {
          name: 'createdAt',
          title: 'creation date',
          class: 'self-start col__21',
        },
      ];
    },

    apiFilters() {
      const filters = {};
      const { searchQuery } = this.filterValues;

      if (searchQuery) {
        filters.q = searchQuery;
      }

      return filters;
    },

    highlight(){
      return prepareWordsModeHighlighter(this.filterValues.searchQuery || '');
    },
  },

  methods: {
    handleRowClick(row) {
      this.$emit('selectItem', row);
    },

    handleTableInitialized(items, count) {
      this.reservationsCount = count;
    },

    setFilterValues(values) {
      if (isEqual(this.filterValues, values)) {
        return;
      }

      if(values.searchQuery !== this.filterValues.searchQuery
        && words(values.searchQuery, /[^,\s]+/g).length > 5){
          this.$refs.filter.$refs.form.finalForm.change('searchQuery', this.filterValues.searchQuery);

        return;
      }

      this.filterValues = values;
    },
  },
};
</script>

<style scoped>
.reservations-list {
  @apply h-full pr-2;

  &__heading {
    @apply flex font-frank font-bold text-sm text-black pt-4;
  }

  &__filters {
    @apply sticky z-10 bg-white;
    top: 76px;
  }

  /* purgecss start ignore */
  & :deep(.col__16) {
    width: 16%;
  }

  & :deep(.col__21) {
    width: 21%;
  }

  & :deep(&__table-header) {
    @apply sticky z-10;
    top: 5rem;
  }

  &--with-toolbar :deep(&__table-header) {
    top: 8.2rem;
  }
  /* purgecss end ignore */

  & :deep(.highlighted) {
    background-color: yellow;
  }
}
</style>
