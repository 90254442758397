export const initialAvailabilitySchedule = [
  {
    days: null,
    openTime: {
      hours: "12",
      minutes: "00",
      clock: "am",
    },
    closeTime: {
      hours: "12",
      minutes: "00",
      clock: "pm",
    },
    working24: false,
  },
];

export const TIMESLOT = {
  HOUR: 'HOUR',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
}

export const initialTimeUnits = [
  {key: TIMESLOT.HOUR, value: 'Hour'},
  {key: TIMESLOT.DAY, value: 'Day'},
  {key: TIMESLOT.WEEK, value: 'Week'},
  {key: TIMESLOT.MONTH, value: 'Month'},
]

export const cancelTypes = {
  MANUAL: 'MANUAL',
  RI_DELETED:'RI_DELETED',
  ASSET_DELETED: 'ASSET_DELETED',
  RI_QTY_DECREASED: 'RI_QTY_DECREASED',
}

