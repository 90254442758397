<template>
  <SelectInput
    :name="name"
    :label="label"
    :options="locations"
    option-key="id"
    option-label="name"
    :hidden="locations.length === 0"
    :validate="validate"
    object-mode
  />
</template>

<script>
import SelectInput from "@/components/form/SelectInput";

export default {
  components: {SelectInput},

  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    parentId: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    label: {
      type: String,
    },

    value: {
      type: String,
    },

    validate: {
      type: Function,
    },
  },

  data() {
    return {
      locations: [],
    };
  },

  mounted() {
    const query = {};

    if (this.parentId) {
      query.parentId = this.parentId;
    }

    this.$riDataProvider.getList('locations', query)
      .then(locations => {
        this.locations = [
          ...locations,
          // {id: 'CUSTOM', name: 'Custom'},
        ];
      });
  },
};
</script>
