<template>
  <div class="reservations-header">
    <img class="reservations-header__image" :src="require('@/assets/modules/NA.png')" />

    <div class="reservations-header__main">
      <h1 class="reservations-header__heading">{{title}}</h1>

      <div class="flex">
        <amount-input
          :value="rentableItem.qty"
          :precision="0"
          amountClass="font-semibold"
          readOnly
        />
        <span class="ml-1"> {{$tc('ri.asset', rentableItem.qty)}}</span>
      </div>

      <div class="flex">
        <amount-input
          :value="rentableItem.timeslot?.rate"
          prefix="$"
          amountClass="font-semibold"
          readOnly
        />
        <span class="ml-1"> /{{rentableItem.timeslot?.unit.toLowerCase() || 'month'}}</span>
      </div>
    </div>

    <div class="reservations-header__side">
      <div class="inline-flex items-center">
        <Tooltip
          v-if="scheduleAvailable"
          icon="clock"
          class="text-graphite-800 font-frank text-xs font-medium"
          :css="{tooltipPopup: 'w-72'}"
          positionX="middle"
        >
          <template v-slot:default>
            <ScheduleTooltipContent
              :schedule="schedule"
              :days="days"
            />
          </template>

          <template v-slot:label>
            <span class="align-middle tracking-wider">availability schedule</span>
          </template>
        </Tooltip>

        <IconButton
          v-if="hasPermission('RI_EDIT')"
          class="ml-6 text-active-500"
          @click="handleEditClick"
          icon="pencil3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import NotifyMixin from '@/mixins/NotifyMixin';
import {
  groupScheduleByDays,
  parseSchedule,
} from '@/utils/Schedule';
import AmountInput from '@/components/ui/AmountInput';
import Tooltip from '@/components/ui/Tooltip';
import ScheduleTooltipContent from "@/components/ri/ScheduleTooltipContent";
import IconButton from "@/components/ui/IconButton";

export default {
  components: {
    AmountInput,
    Tooltip,
    ScheduleTooltipContent,
    IconButton,
  },

  mixins: [NotifyMixin],

  props: {
    rentableItem: {
      required: true,
    },

    title: {
      type: String,
      required: true,
    },
  },

  emits: ['ready', 'edit'],

  data() {
    return {
      days: [],
    };
  },

  computed: {
    ...mapGetters({
      hasPermission: 'ri/hasPermission',
    }),

    schedule() {
      return groupScheduleByDays(parseSchedule(this.rentableItem.availabilitySchedule, this.days));
    },

    scheduleAvailable() {
      return !!this.days.length && Object.keys(this.schedule).length > 0;
    },
  },

  methods: {
    fetchDays() {
      return this.$riDataProvider.getList('weekdays')
        .then(days => {
          this.days = days;
        });
    },

    handleEditClick() {
      this.$emit('edit');
    },
  },

  async mounted() {
    this.fetchDays()
      .catch(error => {
        this.notifyError(error.message);
      })
      .finally(() => {
        this.$emit('ready');
      });
  },
}
</script>

<style scoped>
.reservations-header {
  @apply flex border rounded-sm border-gray-300 bg-graphite-100 p-5;
  
  &__image {
    width: 100px;
    min-width: 100px;
    @apply mr-5;
  }

  &__main {
    @apply flex-1 font-inter text-2sm text-black;
  }
  
  &__heading {
    @apply font-frank text-sm font-bold text-black mb-3 lowercase;
  }

  &__side {
    @apply w-1/3 flex justify-end items-start;
  }
}
</style>
