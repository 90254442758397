<template>
  <canvas
    ref="canvas"
    class="availability-indicator"
    :width="width"
    :height="width"
  />
</template>

<script>
export default {
  props: {
    /**
     * 0 - fully available
     * 1 - fully unavailable
     */
    value: {
      type: Number,
      required: true,
    },
  },

  setup() {
    const width = 32;
    const height = 32;
    const color = '#3498db';

    return {
      width,
      height,
      color,
    };
  },

  data() {
    return {
      ctx: null,
    };
  },

  mounted() {
    this.ctx = this.$refs.canvas.getContext('2d');
    this.render();
  },

  watch: {
    value() {
      this.render();
    },
  },

  methods: {
    render() {
      const {width, height, color, ctx, value} = this;
      const x = width / 2;
      const y = height / 2;
      const r = width / 2 - 2;

      ctx.fillStyle = '#fff';
      ctx.strokeStyle = color;
      ctx.lineWidth = 3;
      ctx.beginPath();
      ctx.arc(x, y, r, 0, 2 * Math.PI);
      ctx.closePath();
      ctx.fill();
      ctx.stroke();

      ctx.fillStyle = color;
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.arc(x, y, r, -0.5 * Math.PI, (2 * value - 0.5) * Math.PI);
      ctx.lineTo(x, y);
      ctx.closePath();
      ctx.fill();
    },
  },
};
</script>

<style scoped>
.availability-indicator {
  @apply w-4 h-4;
}
</style>
