<template>
  <FinalField ref="field" :name="name" :validate="validate">
    <template v-slot="props">
      <div :class="['form-col', $attrs['class']]">
        <label v-if="label">{{ label }}</label>

        <DateTimeInput
          ref="input"
          :model-value="props.value"
          @update:modelValue="props.change"
          @updateMonthYear="val => $emit('updateMonthYear', val)"
          @date-close="$emit('date-close')"
          @update-date="val => $emit('update-date', val)"
          v-bind="inputAttrs"
        />

        <FieldError v-if="!suppressError" :name="name" />
      </div>
    </template>
  </FinalField>
</template>

<script>
import { omit } from "lodash-es";
import { FinalField } from "vue-final-form";
import DateTimeInput from "@/components/ui/DateTimeInput";
import FieldError from "@/components/form/FieldError";

export default {
  components: {
    DateTimeInput,
    FinalField,
    FieldError,
  },

  inheritAttrs: false,

  emits: ['updateMonthYear', 'date-close', 'update-date'],

  props: {
    name: {
      type: String,
      required: true,
    },

    label: {
      type: String,
    },

    validate: {
      type: [Function, Array],
      required: false,
    },

    suppressError: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    inputAttrs() {
      return omit(this.$attrs, "class");
    },
  },

  methods: {
    clear() {
      this.$refs.input.clear();
    },
  },
};
</script>
