<template>
  <div class="h-full">
    <FinalForm
      ref="form"
      class="h-full"
      :initialValues="initialValues"
      :submit="handleSubmit"
      @change.self="handleFormChange"
      :validate="validate"
    >
      <template v-slot="props">
        <form class="h-full flex" @submit="props.handleSubmit">
          <div class="w-2/3 mr-6">
            <div class="row">
              <h2 class="heading mb-6">rental data</h2>

              <div class="mb-4">
                <div class="flex flex-row">
                  <div class="flex-1 flex-grow-2">
                    <DateTimeField
                      ref="dateTimeInput"
                      name="startTime"
                      label="start date*"
                      :validate="required"
                      :suppressError="
                        isStartAvailabilityErrorVisible(props) ||
                        isStartTimeAvailabilityErrorVisible(props)
                      "
                      :inCommunityTimezone="false"
                      :formatter="getFormattedDate"
                      :allowedDates="allowedDates"
                      format="ll"
                      :monthYear="monthYearPicker"
                      monthNameFormat="long"
                      @dateClose="onCalendarClose"
                      @updateDate="updateStartDate"
                      @updateMonthYear="handleUpdateMonthYear"
                      timePlaceholder="Time"
                      :options="availableTime"
                      :timeDisabled="!this.formValues.start"
                      :timeEditMode="hasTimeInput"
                      :timeInputEditable="false"
                    />
                  </div>

                  <div class="flex-1 ml-8">
                    <CounterField
                      name="duration"
                      :class="{ 'w-36': !maxDuration }"
                      :label="durationLabel"
                      :min="1"
                      :max="maxDuration"
                      :disabled="durationDisabled"
                      :computeWidth="counterComputeWidth"
                    />

                    <div
                      class="form-error"
                      v-if="props.errors.restrictedDuration"
                    >
                      {{ props.errors.restrictedDuration }}
                    </div>
                  </div>

                  <div class="form-col">
                    <label>end date</label>

                    <div v-if="endDateTime" class="end-date">
                      <div>{{ endDateTime.date }}</div>
                      <div>{{ endDateTime.time }}</div>
                    </div>

                    <div v-else class="empty">Not specified</div>
                  </div>
                </div>

                <FinalField name="startAvailability">
                  <template v-slot="{ name }">
                    <FieldError
                      v-if="!props.dirtyFieldsSinceLastSubmit?.assetsIds"
                      :name="name"
                    />
                  </template>
                </FinalField>

                <FinalField name="startTimeAvailability">
                  <template v-slot="{ name }">
                    <FieldError
                      v-if="!props.dirtyFieldsSinceLastSubmit?.assetsIds"
                      :name="name"
                    />
                  </template>
                </FinalField>

                <div
                  v-if="this.riTimeslot === TIMESLOT.DAY && endDateReduced"
                  class="flex mt-2"
                >
                  <Icon
                    name="info"
                    class="w-4 min-w-4 h-4 text-blue-600 mr-1"
                  />
                  <span class="font-frank font-medium text-xs text-blue-800">{{
                    $t("ri.reduced_reservation_period", {
                      period: durationWithTimeslotLabel,
                    })
                  }}</span>
                </div>
              </div>

              <div class="form-row w-1/3" v-if="rentableItem.isFungible">
                <amount-field
                  :precision="0"
                  name="qty"
                  :disabled="assetsDisabled"
                  :label="availableAssetsLabel"
                  :computeWidth="false"
                  textClass=""
                  :min="0"
                  :max="assetsTotal"
                  :validate="composeValidators(required, minValue(1))"
                  :suppressError="assetsDisabled"
                />
              </div>

              <div class="form-row" v-if="rentableItem.isFungible === false">
                <SelectInput
                  ref="assets"
                  name="assetsIds"
                  :disabled="assetsDisabled"
                  placeholder="Select assets"
                  :label="availableAssetsLabel"
                  @optionsLoaded="onAssetsLoaded"
                  :dataProvider="$riDataProvider"
                  :requestParams="assetsRequestParams"
                  resource="assets"
                  :validate="notEmpty"
                  option-key="id"
                  option-label="name"
                  lazyLoad
                  multiple
                />
              </div>

              <FieldError name="assetsAvailability" />
            </div>

            <div class="row">
              <h2 class="heading mb-6">personal data</h2>

              <div class="form-row">
                <SelectInput
                  label="building*"
                  name="buildingId"
                  :validate="required"
                  :options="buildings"
                  option-key="id"
                  option-label="name"
                  placeholder="Select"
                  :suppressError="isProfileAvailabilityErrorVisible(props)"
                />

                <SelectInput
                  label="unit*"
                  name="unitId"
                  :validate="required"
                  :options="units"
                  :disabled="!formValues.buildingId"
                  option-key="id"
                  option-label="name"
                  placeholder="Select"
                  :suppressError="isProfileAvailabilityErrorVisible(props)"
                />
              </div>

              <div class="form-row">
                <SelectInput
                  label="resident name*"
                  name="profileId"
                  :validate="required"
                  :options="transformedProfiles"
                  :disabled="!formValues.unitId"
                  placeholder="Select"
                  :suppressError="isProfileAvailabilityErrorVisible(props)"
                />

                <div class="form-col">
                  <label>resident contacts</label>

                  <template v-if="selectedProfile">
                    <div
                      class="resident-contact"
                      :key="index"
                      v-for="(contact, index) in profileContacts"
                    >
                      {{ contact }}
                    </div>
                  </template>

                  <div v-else class="empty">Not specified</div>
                </div>
              </div>

              <FieldError name="profileAvailability" />
            </div>
          </div>
          <div class="w-1/3 bg-graphite-200 rounded-sm p-5">
            <div>
              <h2 class="heading">{{ rentableItem.name }}</h2>
              <div class="location">{{ rentableItemLocation }}</div>
              <div class="flex">
                <amount-input
                  :value="rentableItem.timeslot?.rate"
                  prefix="$"
                  amountClass="text-2sm text-black font-inter font-semibold"
                  readOnly
                />

                <span class="font-inter text-2sm ml-1 text-black">
                  /{{ riTimeslot?.toLowerCase() || "month" }}
                </span>
              </div>
            </div>

            <hr class="my-6" />

            <div>
              <h2 class="heading mb-3">payment</h2>

              <template v-if="formValues.total">
                <div class="text-xs text-black font-inter font-semibold">
                  {{ totalLabel }}
                </div>

                <div class="form-row">
                  <amount-field
                    name="total"
                    prefix="$"
                    :editMode="false"
                    textClass="font-inter text-2sm text-black font-bold"
                  />
                </div>
              </template>

              <div v-else class="empty">Not specified</div>
            </div>
          </div>
          <modal-footer :footer="footer" :tertiary="close"></modal-footer>
        </form>
      </template>
    </FinalForm>
    <loader :loading="loading" :backdrop="true" />
  </div>
</template>

<script>
import moment from "moment";
import { i18n } from '@/i18n';
import { pick, isEqual, debounce } from "lodash-es";
import { FinalForm, FinalField } from "vue-final-form";
import DateTimeField from "@/components/form/DateTimeField";
import AmountField from "@/components/form/AmountField";
import AmountInput from "@/components/ui/AmountInput";
import SelectInput from "@/components/form/SelectInput.vue";
import CounterField from "@/components/form/CounterField";
import NotifyMixin from "@/mixins/NotifyMixin";
import ModalNavigation from "@/mixins/ModalNavigation";
import ValidatorMixin from "@/components/form/ValidatorMixin";
import Loader from "@/components/ui/Loader";
import Icon from "@/components/ui/Icon";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import FieldError from "@/components/form/FieldError";
import { formatPhone } from "@/utils/String";
import { TIMESLOT } from "@/views/ri/constants";

export default {
  components: {
    AmountField,
    AmountInput,
    FinalForm,
    CounterField,
    SelectInput,
    Loader,
    Icon,
    ModalFooter,
    FieldError,
    FinalField,
    DateTimeField,
  },

  data: () => {
    return {
      initialValues: {
        start: null,
        startTime: null,
        end: null,
        duration: 1,
        qty: 0,
        assetsIds: [],
        buildingId: null,
        unitId: null,
        profileId: null,
        total: null,
        startAvailability: null,
        startTimeAvailability: null,
        assetsAvailability: [],
        profileAvailability: null,
      },
      formValues: {},
      rentableItem: {},
      loading: false,
      endDateReduced: false,
      assetsDisabled: false,

      // personal data
      buildings: [],
      units: [],
      profiles: [],

      // rental data
      availableDates: [],
      availableDateTimes: [],
      сalendarActiveMonthYear: { month: 0, year: 0 },
      assetsTotal: 0,
      assetsRequestParams: {
        riId: null,
        availableFrom: null,
        availableTo: null,
      },
    };
  },

  mixins: [NotifyMixin, ModalNavigation, ValidatorMixin],

  computed: {
    monthYearPicker() {
      return () =>
        import(
          /* webpackChunkName: "TabbedMonthYearPicker" */ `@/components/ui/datepicker/TabbedMonthYearPicker`
        );
    },

    rentableItemId() {
      return this.$route.params.rentableItemId;
    },

    riTimeslot() {
      return this.rentableItem?.timeslot?.unit;
    },

    finalForm() {
      return this.$refs.form.finalForm;
    },

    selectedProfile() {
      if (this.formValues.profileId) {
        return this.profiles.find(({ id }) => id === this.formValues.profileId);
      }

      return null;
    },

    profileContacts() {
      return [
        ...this.selectedProfile.contactEmails,
        ...this.selectedProfile.contactPhones.map((phone) =>
          formatPhone(phone)
        ),
      ];
    },

    rentableItemLocation() {
      return (
        this.rentableItem?.locationsChain?.map(({ name }) => name).join(", ") ||
        ""
      );
    },

    footer() {
      return {
        primaryButton: "make reservation",
        tertiaryButton: "cancel",
      };
    },

    transformedProfiles() {
      return this.profiles.map(({ id, firstName, lastName }) => ({
        key: id,
        value: `${firstName} ${lastName}`,
      }));
    },

    allowedDates() {
      if (!this.availableDates.length) {
        return [null];
      }

      return this.availableDates;
    },

    endDateTime() {
      const { end } = this.formValues;

      if (!end) return null;

      const m = moment(end);

      return {
        date: m.format("ll"),
        time: m.format("hh:mm a"),
      };
    },

    startTime() {
      const { start } = this.formValues;

      if (!start) return null;

      return moment(start).format("hh:mm a");
    },

    durationDisabled() {
      return !this.formValues.start && !this.formValues.qty;
    },

    availableAssetsLabel() {
      return this.assetsDisabled
        ? 'select start time' :
       `${this.assetsTotal} ${this.$i18n.tc(
            'ri.asset',
            this.assetsTotal
          )} available*`;
    },

    totalLabel() {
      const { qty } = this.formValues;
      const assetsLabel = this.$tc("ri.asset", qty);

      return `${qty} ${assetsLabel} x ${this.durationWithTimeslotLabel}`;
    },

    durationLabel() {
      return `duration (${this.getTimeslotLabel(0)})*`;
    },

    durationWithTimeslotLabel() {
      const { duration } = this.formValues;
      const timeslotLabel = this.getTimeslotLabel(duration);

      return `${duration} ${timeslotLabel}`;
    },

    maxDuration() {
      switch (this.riTimeslot) {
        case this.TIMESLOT.HOUR:
          return 24;
        case this.TIMESLOT.MONTH:
          return 120;
        default:
          return undefined;
      }
    },

    availableTime() {
      return this.availableDateTimes.map(({ start }) => {
        const time = moment(start).format("hh:mm a");

        return {
          key: time,
          value: time,
        };
      });
    },

    hasTimeInput() {
      switch (this.riTimeslot) {
        case this.TIMESLOT.HOUR:
        case this.TIMESLOT.DAY:
          return true;
        default:
          return false;
      }
    },

    counterComputeWidth() {
      return typeof this.maxDuration === "number";
    },

    startDateIncomplete() {
      return this.formValues.start && !this.formValues.startTime;
    },
  },

  methods: {
    validate({ startTime, end }) {
      return {
        restrictedDuration:
          startTime && !end
            ? i18n.global.t("ri.restricted_duration")
            : undefined,
      };
    },

    isEndReduced({ startTime, duration, end }) {
      if (!startTime || !end) {
        return false;
      }

      const computedEnd = moment(startTime).add(duration, "days");

      return moment(end).isBefore(computedEnd, "day");
    },

    onAssetsLoaded(totalSize) {
      if(!this.startDateIncomplete) {
        this.assetsTotal = totalSize;
      }

      if(!this.startDateIncomplete && this.assetsDisabled){
        this.assetsDisabled = false;
      }
    },

    handleUpdateMonthYear({ value, isMonth }) {
      if (!isMonth && value === this.сalendarActiveMonthYear.year) {
        return;
      }

      if (isMonth) {
        this.сalendarActiveMonthYear = {
          ...this.сalendarActiveMonthYear,
          month: value,
        };
      } else {
        this.сalendarActiveMonthYear = {
          ...this.сalendarActiveMonthYear,
          year: value,
        };
      }

      this.availableDates = [];
    },

    async handleSubmit(values) {
      this.loading = true;

      const data = {
        start: values.startTime,
        ...pick(values, ["profileId", "end", "duration", "qty", "assetsIds"]),
      };

      try {
        const errors = await this.validateReservation(data);

        if (errors) {
          return errors;
        }

        await this.$riDataProvider.create("reservations", {
          riId: this.rentableItemId,
          data,
        });

        this.notifySuccess("The reservation was successfully created");
        this.close();
      } catch (error) {
        this.notifyError(error.message);
      } finally {
        this.loading = false;
      }
    },

    async validateReservation(data) {
      const {
        availableAssets,
        isResidentProfileValid,
        isDateRangeValid,
        availableCalendarPeriods,
      } = await this.$riDataProvider.create("validateReservation", {
        riId: this.rentableItemId,
        data,
      });
      const startDateTime = data.start;
      const assets = this.rentableItem.isFungible ? data.qty : data.assetsIds;
      const assetsCount = Array.isArray(assets) ? assets.length : assets;
      let errors = null;

      const preparedAvailablePeriods = this.hasTimeInput
        ? availableCalendarPeriods
        : availableCalendarPeriods.map(({ start }) =>
            moment(start).format("YYYY-MM-DD")
          );

      if (!isResidentProfileValid) {
        const selectedProfile = this.selectedProfile;

        this.updateProfileAvailability(isResidentProfileValid);
        
        errors = {
          profileAvailability: this.validProfile(
            selectedProfile,
            isResidentProfileValid
          ),
        };
      }

      if (!isDateRangeValid) {
        if (this.hasTimeInput) {
          if (preparedAvailablePeriods.length) {
            const formattedStartTime = moment(startDateTime).format("hh:mm a");
            this.updateStartTimeAvailability(preparedAvailablePeriods);

            errors = Object.assign({}, errors, {
              startTimeAvailability: this.validStartDateTime(
                formattedStartTime,
                isDateRangeValid
              ),
            });
          } else {
            await this.fetchAvailableDates();
            const selectedDayAvailable =
              this.availableDates.findIndex(
                (date) => date === this.formValues.start
              ) !== -1;

            if (!selectedDayAvailable) {
              const formattedStartDate = moment(startDateTime).format("LL");
              this.updateStartDateAvailability();

              errors = Object.assign({}, errors, {
                startAvailability: this.validStartDate(
                  formattedStartDate,
                  isDateRangeValid
                ),
              });
            }
          }
        } else {
          const formattedStartDate = moment(startDateTime).format("LL");
          this.updateStartDateAvailability(preparedAvailablePeriods);

          errors = Object.assign({}, errors, {
            startAvailability: this.validStartDate(
              formattedStartDate,
              isDateRangeValid
            ),
          });
        }
      } else if (availableAssets.count !== assetsCount) {
        this.updateAssetsAvailability(assetsCount, availableAssets);
        errors = Object.assign({}, errors, {
          assetsAvailability: this.validAssets(assets, availableAssets),
        });
      }

      return errors;
    },

    updateProfileAvailability() {
      this.unwatchProfileAvailability();
      this.unwatchProfileAvailability = null;

      this.profiles = this.profiles.filter(
        (profile) => profile.id !== this.selectedProfile.id
      );
      this.finalForm.change("profileId", null);

      if (!this.profiles.length) {
        this.units = this.units.filter(
          (unit) => unit.id !== this.formValues.unitId
        );
        this.finalForm.change("unitId", null);

        if (!this.units.length) {
          this.buildings = this.buildings.filter(
            (building) => building.id !== this.formValues.buildingId
          );
          this.finalForm.change("buildingId", null);
        }
      }
    },

    updateStartDateAvailability(availableDates) {
      this.unwatchStartAvailability();
      this.unwatchStartAvailability = null;

      this.finalForm.change("start", null);
      this.finalForm.change("startTime", null);

      if (Array.isArray(availableDates)) {
        this.availableDates = availableDates;
      }
    },

    updateStartTimeAvailability(availableDateTimes) {
      this.unwatchStartTimeAvailability();
      this.unwatchStartTimeAvailability = null;

      this.finalForm.change("startTime", null);
      this.availableDateTimes = availableDateTimes;
    },

    updateAssetsAvailability(assetsCount, availableAssets) {
      if (assetsCount > availableAssets.count) {
        this.unwatchAssetsAvailability();
        this.unwatchAssetsAvailability = null;

        if (this.rentableItem.isFungible) {
          this.onAssetsLoaded(availableAssets.count);
        } else {
          this.finalForm.change("assetsIds", availableAssets.assetsIds);
          //force Dropdown assets list reload
          this.assetsRequestParams = { ...this.assetsRequestParams };
        }
      }
    },

    validProfile({ firstName, lastName }, isValid) {
      const username = `${firstName} ${lastName}`;

      return isValid
        ? undefined
        : this.$i18n.t("ri.invalid_profile", { username });
    },

    validStartDate(date, isValid) {
      return isValid
        ? undefined
        : this.$i18n.t("ri.invalid_start_date", { date });
    },

    validStartDateTime(datetime, isValid) {
      return isValid
        ? undefined
        : this.$i18n.t("ri.invalid_start_datetime", { datetime });
    },

    validAssets(assets = [], availableAssets = []) {
      const assetsTotal = Array.isArray(assets) ? assets.length : assets;
      const unavailableCount = assetsTotal - availableAssets.count;

      return unavailableCount === 0
        ? undefined
        : this.$i18n.tc("ri.invalid_assets", unavailableCount, {
            count: unavailableCount,
            total: assetsTotal,
          });
    },

    fetchRentableItem() {
      return this.$riDataProvider
        .getOne("rentableItems", { id: this.rentableItemId })
        .then((rentableItem) => {
          this.rentableItem = rentableItem;
        });
    },

    fetchBuildings() {
      this.$riDataProvider.get("buildings").then((buildings) => {
        this.buildings = buildings;
      });
    },

    fetchUnits() {
      this.$riDataProvider
        .get("units", { buildingId: this.formValues.buildingId })
        .then((units) => {
          this.units = units;
        });
    },

    fetchProfiles() {
      this.$riDataProvider
        .get("profiles", {
          buildingId: this.formValues.buildingId,
          unitId: this.formValues.unitId,
        })
        .then((profiles) => {
          this.profiles = profiles;
        });
    },

    // fetch for RI with fungible assets
    fetchFungibleAssets() {
      this.$riDataProvider
        .getList("assets", this.assetsRequestParams)
        .then(({ totalSize }) => {
          this.onAssetsLoaded(totalSize);
        });
    },

    fetchAvailableDates(values) {
      const { start, duration, assetsIds, qty } = {
          ...this.formValues,
          ...values,
        },
        data = {
          riId: this.rentableItemId,
          duration,
        },
        { isFungible } = this.rentableItem;

      if (isFungible && qty) {
        data.qty = qty;
      } else if (Array.isArray(assetsIds) && assetsIds.length) {
        data.assetsIds = assetsIds.join(",");
      }

      if (start) {
        data.datetime = this.getFormattedDate(start);
      }

      return this.$riDataProvider
        .availableDays("reservations", data)
        .then((availableDates) => {
          this.availableDates = availableDates;
        });
    },

    fetchAvailableDateTimes() {
      const { start: date, duration, assetsIds, qty } = this.formValues,
        data = {
          riId: this.rentableItemId,
          date,
          duration,
        };
      const { isFungible } = this.rentableItem;

      if (isFungible && qty) {
        data.qty = qty;
      } else if (Array.isArray(assetsIds) && assetsIds.length) {
        data.assetsIds = assetsIds.join(",");
      }

      return this.$riDataProvider
        .availableTime("reservations", data)
        .then((availableDateTimes) => {
          this.availableDateTimes = availableDateTimes;
        });
    },

    handleFormChange({ values }) {
      this.formValues = values;
    },

    resetDuration() {
      this.finalForm.change("duration", 1);
    },

    getAvailableDateTimeByStart(dateTime) {
      return (
        this.availableDateTimes.find(({ start }) => start === dateTime) || null
      );
    },

    initializeWatchers() {
      this.$watch('startDateIncomplete', (value) => {
        if(value) {
          this.assetsDisabled = true;
        }
      });

      if(this.rentableItem.isFungible) {
        this.$watch('assetsRequestParams', () => {
          this.fetchFungibleAssets();
          },
          {
            deep: true,
          },
        )
      } else {
        this.$watch('assetsDisabled', (value) => {
          if(value) {
            this.$refs.assets.$refs.select.closeDropdown();
          }
        });
      }

      //payment
      this.$watch(
        () => [this.formValues.duration, this.formValues.qty],
        (val, oldVal) => {
          if (isEqual(val, oldVal)) return;

          const [duration, qty] = val;
          const total = duration * qty * this.rentableItem?.timeslot?.rate;

          this.finalForm.change("total", isNaN(total) ? null : total);
        },
        {
          immediate: true,
        }
      );

      // personal data
      this.$watch("formValues.buildingId", (buildingId) => {
        if (buildingId) {
          this.fetchUnits();
        } else {
          this.units = [];
          this.finalForm.change("unitId", null);
        }
      });

      this.$watch("formValues.unitId", (unitId) => {
        if (unitId) {
          this.fetchProfiles();
        } else {
          this.profiles = [];
          this.finalForm.change("profileId", null);
        }
      });

      this.$watch("formValues.profileId", () => {
        if (!this.unwatchProfileAvailability) {
          this.unwatchProfileAvailability = this.watchProfileAvailability();
        }
      });

      //rental data
      this.$watch("formValues.start", async (start) => {
        const { startTime } = this.formValues;

        if (start) {
          await this.fetchAvailableDateTimes();

          if (this.hasTimeInput) {
            if (startTime) {
              const endDate = this.getAvailableDateTimeByStart(startTime);

              if (endDate) {
                this.updateEndDate({ startTime });
              } else {
                this.finalForm.change("startTime", null);
              }
            } else {
              this.setAssetsParams({
                availableFrom: this.getFormattedDate(start),
                availableTo: this.getFormattedDate(
                  moment.utc(start).endOf("day")
                ),
              });
            }
          } else {
            const newStartTime = this.availableDateTimes[0].start;

            this.finalForm.change("startTime", newStartTime);

            this.updateEndDate({ startTime: newStartTime });
          }
        } else {
          if (!startTime) {
            this.setAssetsParams({ availableFrom: null, availableTo: null });
          }

          // in case date field was cleared programmatically
          this.$refs.dateTimeInput.$refs.input.dateValue = undefined;

          this.availableDateTimes = [];
        }

        if (!this.unwatchStartAvailability) {
          this.unwatchStartAvailability = this.watchStartAvailability();
        }
      });

      this.$watch("formValues.startTime", (startTime, oldStartTime) => {
        const dateChanged =
          startTime &&
          oldStartTime &&
          !moment(startTime).isSame(oldStartTime, "day");

        if (!dateChanged) {
          this.updateEndDate({ startTime });
        }

        if (!startTime) {
          this.$refs.dateTimeInput.$refs.input.timeValue = undefined;
        }

        if (!this.unwatchStartTimeAvailability) {
          this.unwatchStartTimeAvailability = this.watchStartTimeAvailability();
        }
      });

      this.$watch(
        "formValues.assetsIds",
        (assetsIds, oldAssetsIds) => {
          if (isEqual(assetsIds, oldAssetsIds)) return;

          if (
            !this.rentableItem.isFungible &&
            !this.unwatchAssetsAvailability
          ) {
            this.unwatchAssetsAvailability =
              this.watchNonFungibleAssetsAvailability();
          }

          this.finalForm.change("qty", assetsIds.length);
        },
        {
          deep: true,
        }
      );

      this.$watch("formValues.qty", () => {
        if (!this.formValues.start) {
          this.fetchAvailableDates();
        } else {
          this.fetchAvailableDateTimes();
        }

        if (this.rentableItem.isFungible) {
          if (!this.unwatchAssetsAvailability) {
            this.unwatchAssetsAvailability =
              this.watchFungibleAssetsAvailability();
          }
        }
      });

      this.$watch(
        () => [this.formValues.start, this.formValues.qty],
        () => {
          if (this.durationDisabled) {
            this.resetDuration();
          }
        }
      );

      this.$watch("formValues.duration", async (duration) => {
        const { start, startTime } = this.formValues;

        if (!start) {
          return;
        }

        this.endDateReduced = false;

        await this.fetchAvailableDateTimes();

        const selectedStartTimeExist =
          this.getAvailableDateTimeByStart(startTime);

        if (selectedStartTimeExist) {
          this.updateEndDate({ startTime, duration });
        } else {
          this.updateEndDate({ duration });
        }
      });

      this.$watch("formValues.end", (end) => {
        let availableFrom = null;
        let availableTo = null;
        const { start, startTime } = this.formValues;

        if (end) {
          availableFrom = startTime;
          availableTo = end;
        } else {
          if (this.hasTimeInput && start) {
            availableFrom = this.getFormattedDate(start);
            availableTo = this.getFormattedDate(moment.utc(start).endOf("day"));
          }
        }

        this.setAssetsParams({ availableFrom, availableTo });
      });

      this.$watch("assetsTotal", (assetsTotal) => {
        if (this.rentableItem.isFungible && this.formValues.qty > assetsTotal) {
          this.finalForm.change("qty", assetsTotal);
        }
      });

      this.$watch(
        "сalendarActiveMonthYear",
        debounce(({ month, year }) => {
          this.fetchAvailableDates({
            start: { year, month, day: 1 },
          });
        }, 300),
        {
          deep: true,
        }
      );

      // availability watchers
      this.unwatchStartAvailability = this.watchStartAvailability();

      this.unwatchAssetsAvailability = this.rentableItem.isFungible
        ? this.watchFungibleAssetsAvailability()
        : this.watchNonFungibleAssetsAvailability();

      this.unwatchProfileAvailability = this.watchProfileAvailability();
    },

    watchFungibleAssetsAvailability() {
      return this.$watch("formValues.qty", (qty) => {
        this.finalForm.change("assetsAvailability", qty);
      });
    },

    watchNonFungibleAssetsAvailability() {
      return this.$watch(
        "formValues.assetsIds",
        (assetsIds, oldAssetsIds) => {
          if (isEqual(assetsIds, oldAssetsIds)) return;

          this.finalForm.change("assetsAvailability", assetsIds);
        },
        {
          deep: true,
        }
      );
    },

    watchProfileAvailability() {
      return this.$watch("formValues.profileId", (profileId) => {
        this.finalForm.change("profileAvailability", profileId);
      });
    },

    watchStartAvailability() {
      return this.$watch("formValues.start", (start) => {
        this.finalForm.change("startAvailability", start);
      });
    },

    watchStartTimeAvailability() {
      return this.$watch("formValues.startTime", (startTime) => {
        this.finalForm.change("startTimeAvailability", startTime);
      });
    },

    updateStartDate(value) {
      this.finalForm.change("start", value);
    },

    updateEndDate({ startTime = null, duration = this.formValues.duration }) {
      const end = this.getAvailableDateTimeByStart(startTime)?.end || null;

      this.endDateReduced = this.isEndReduced({ startTime, duration, end });

      if (this.formValues.end !== end) {
        this.finalForm.change("end", end);
      }
    },

    onCalendarClose() {
      this.fetchAvailableDates({
        start: this.getFormattedDate(),
      });
    },

    setAssetsParams(params) {
      if (Object.hasOwn(params, "availableFrom")) {
        this.assetsRequestParams.availableFrom = params.availableFrom;
      }

      if (Object.hasOwn(params, "availableTo")) {
        this.assetsRequestParams.availableTo = params.availableTo;
      }

      if (Object.hasOwn(params, "riId")) {
        this.assetsRequestParams.riId = params.riId;
      }
    },

    getFormattedDate(date) {
      const formatTemplate = "YYYY-MM-DDTHH:mm:ss";

      if (date) {
        return moment.utc(date).format(formatTemplate);
      }

      return moment().format(formatTemplate);
    },

    isStartAvailabilityErrorVisible(props) {
      return (
        props.submitErrors?.startAvailability &&
        !props.dirtyFieldsSinceLastSubmit?.startAvailability &&
        !props.dirtyFieldsSinceLastSubmit?.assetsIds
      );
    },

    isStartTimeAvailabilityErrorVisible(props) {
      return (
        props.submitErrors?.startTimeAvailability &&
        !props.dirtyFieldsSinceLastSubmit?.startTimeAvailability &&
        !props.dirtyFieldsSinceLastSubmit?.assetsIds
      );
    },

    isProfileAvailabilityErrorVisible(props) {
      return (
        props.submitErrors?.profileAvailability &&
        !props.dirtyFieldsSinceLastSubmit?.profileAvailability
      );
    },

    getTimeslotLabel(count = 1) {
      return this.$tc(`ri.periods.${this.riTimeslot}`, count);
    },
  },

  created() {
    this.formValues = this.initialValues;
    this.setAssetsParams({ riId: this.rentableItemId });
    this.TIMESLOT = TIMESLOT;
  },

  mounted() {

    this.loading = true;

    Promise.all([
      this.fetchBuildings(),
      this.fetchRentableItem().then(() => {
        this.initializeWatchers();

        if (this.rentableItem.isFungible) {
          return [this.fetchFungibleAssets(), this.fetchAvailableDates()];
        }

        return this.fetchAvailableDates();
      }),
    ])
      .catch((error) => this.notifyError(error.message))
      .finally(() => (this.loading = false));
  },
};
</script>

<style scoped>
.heading {
  @apply text-black text-sm font-frank font-bold;
}

.start-time {
  @apply flex items-end text-black font-inter text-2sm -ml-4;
  margin-bottom: 1.8rem;
  min-width: 4rem;
}

.end-date {
  @apply text-black font-inter text-2sm;
}

.location {
  @apply text-2xs text-black font-inter mb-2 leading-4;
}

.resident-contact {
  @apply text-black text-2sm font-inter;
}

.row + .row {
  @apply mt-10;
}

.empty {
  @apply text-graphite-700 text-2sm font-inter;
}
</style>
