<template>
  <div
    class="validation-message"
    :class="{
      'validation-message--error': type === 'error',
      'validation-message--success': type === 'success',
    }"
  >
    <Icon :name="iconName" class="validation-message__icon" />
    {{ text }}
  </div>
</template>

<script>
import Icon from "@/components/ui/Icon";

export default {
  components: { Icon },

  props: {
    /**
     * Message text
     */
    text: {
      type: String,
    },
    /**
     * Message type `success | error`
     */
    type: {
      type: String,
      validator: function(value){
        return ['success', 'error', undefined].indexOf(value);
      },
    }
  },

  computed: {
    iconName(){
      switch(this.type){
        case 'success': return 'checkcircle';
        default: return 'warning2';
      }
    }
  }
}
</script>

<style scoped>
  .validation-message {
    @apply flex items-center px-2 py-1 border border-transparent rounded-sm text-xs font-medium font-frank;

    &--error {
      @apply border bg-red-100 border-red-300 text-red-500;
    }

    &--success {
      @apply border bg-leaf-100 border-leaf-200 text-leaf-600;
    }
  }

  .validation-message__icon {
    @apply inline h-3 w-3 mr-1;
  }
</style>
