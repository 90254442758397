<template>
  <div class="reservations-toolbar">
    <div v-if="$slots.main || $slots.right" class="reservations-toolbar__row reservations-toolbar__row--sectioned">
      <div class="reservations-toolbar__section reservations-toolbar__section--main">
        <slot name="main" />
      </div>
      <div class="reservations-toolbar__section">
        <slot name="right" />
      </div>
    </div>
    <div v-if="$slots.filters" class="reservations-toolbar__row">
      <slot name="filters" />
    </div>
  </div>
</template>

<style scoped>
.reservations-toolbar {
  @apply pt-6 sticky top-0 z-10 bg-white;

  &__row {
    &--sectioned {
      @apply flex justify-between pb-4;
    }
  }

  &__section {
    @apply flex items-center;

    &--main {
      @apply flex-1;
    }
  }
}
</style>