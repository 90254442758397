<template>
  <FinalField :name="name" :validate="validate" ref="field">
    <template v-slot="props">
      <div :class="['form-col', $attrs['class']]">
        <label v-if="label" :for="name">{{label}}</label>

        <counter-input
          :value="props.value"
          :class="inputClass"
          v-bind="{...inputAttrs, ...getCounterProps(props)}"
        />

        <FieldError v-if="!suppressError" :name="name" />
      </div>
    </template>
  </FinalField>
</template>

<script>
import {omit} from "lodash-es";
import { FinalField } from "vue-final-form";
import CounterInput from '@/components/ui/CounterInput';
import FieldError from "@/components/form/FieldError";

export default {
  components: {CounterInput, FieldError, FinalField},

  inheritAttrs: false,

  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    validate: {
      type: [Function, Array],
      required: false,
    },
    editMode: {
      type: Boolean,
      default: true,
    },
    textClass: {
      type: String,
    },
    inputClass: {
      type: String,
    },
    suppressError: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  computed: {
    inputAttrs(){
      return omit(this.$attrs, 'class');
    },
  },

  methods: {
    handleChange(value){
      this.$refs.field.fieldState.change(value);
    },

    getCounterProps(props){
      if(this.editMode){
        return {
          name: props.name,
          amountClass: [this.textClass, { error: props.meta.error && props.meta.touched }],
          onFocus: props.events.focus,
          onBlur: props.events.blur,
          onChange: this.handleChange,
        };
      }

      return {
        amountClass: this.textClass,
        readOnly: true,
      };
    },
  },
};
</script>
