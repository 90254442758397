<template>
  <div class="timeline-columns">
    <div class="timeline-columns__sidebar" :class="sidebarClass">
      <slot name="sidebar" />
    </div>

    <div class="timeline-columns__content">
      <div class="timeline-columns__content-inner">
        <transition-group
          tag="div"
          class="timeline-columns__periods"
          :name="`timeline-columns__period--${direction}`"
          :style="{
            '--period-width': `calc(100% / ${timeWindow.periods.length})`,
          }"
        >
          <div
            v-for="period in timeWindow.periods"
            :key="period.startDate.valueOf()"
            class="timeline-columns__period"
          >
            <slot name="period" :period="period" />
          </div>
        </transition-group>

        <slot name="content" />
      </div>

      <slot name="overlay" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    timeWindow: {
      type: Object,
      required: true,
    },

    sidebarClass: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      direction: '',
    };
  },

  watch: {
    timeWindow(value, oldValue) {
      if (!value || !oldValue) {
        this.direction = '';
      }

      this.direction = value.startDate < oldValue.startDate
        ? 'prev'
        : 'next';
    },
  },
};
</script>

<style scoped>
.timeline-columns {
  @apply flex h-full;

  &__sidebar {
    @apply border-r border-b border-graphite-500;
  }

  &__content {
    @apply flex-grow relative;
  }

  &__content-inner {
    @apply relative overflow-hidden w-full h-full;
  }

  &__periods {
    @apply flex h-full;
  }

  &__period {
    @apply transition-all duration-500 border-graphite-500 border-b mx-0;
    width: var(--period-width);

    &:not(:last-child) {
      @apply border-r;
    }

    &--prev-enter-from,
    &--next-leave-to {
      margin-left: calc(0% - var(--period-width));
    }

    &--prev-leave-to,
    &--next-enter-from {
      margin-right: calc(0% - var(--period-width));
    }
  }
}
</style>
