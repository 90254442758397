<template>
  <Tooltip
    class="timeline-period"
    position-x="middle"
    position-y="bottom"
    :css="{
      tooltipPopup: 'timeline-period__popup',
    }"
    :disabled="!tiny"
  >
    <template #label>
      <div class="timeline-period__header">
        <template v-if="timeslot === TIMESLOT.MONTH">
          <div>{{period.startDate.format('MMMM')}}</div>
          <div>{{period.startDate.format('D')}}-{{period.lastDate.format('D')}}</div>
        </template>
        <template v-if="timeslot === TIMESLOT.WEEK">
          <template v-if="period.startDate.month() === period.lastDate.month()">
            <div>{{period.startDate.format('MMM')}}</div>
            <div>{{period.startDate.format('D')}}-{{period.lastDate.format('D')}}</div>
          </template>
          <template v-else>
            <div>{{period.startDate.format('MMM D')}} -</div>
            <div>{{period.lastDate.format('MMM D')}}</div>
          </template>
        </template>
        <template v-if="timeslot === TIMESLOT.DAY">
          <div>{{period.startDate.format('dddd')}}</div>
          <div>{{period.startDate.format('D')}}</div>
        </template>
        <template v-if="timeslot === TIMESLOT.HOUR">
          <div>{{period.startDate.format('h')}}</div>
          <div>{{period.startDate.format('a')}}</div>
        </template>
      </div>
      <TimelinePeriodAvailability
        v-if="stats"
        :stats="stats"
        :tiny="tiny"
      />
    </template>

    <template #default>
      <TimelinePeriodAvailability
        class="timeline-period__tooltip"
        v-if="stats"
        :stats="stats"
      />
    </template>
  </Tooltip>
</template>

<script>
import TimelinePeriodAvailability from "@/components/ri/reservations/TimelinePeriodAvailability";
import Tooltip from "@/components/ui/Tooltip";
import {TIMESLOT} from "@/views/ri/constants";

export default {
  components: {Tooltip, TimelinePeriodAvailability},

  props: {
    timeslot: {
      type: String,
      required: true,
    },

    period: {
      type: Object,
      required: true,
    },

    stats: {
      type: Object,
      required: true,
    },
  },

  setup() {
    return {
      TIMESLOT,
    };
  },

  computed: {
    tiny() {
      return this.timeslot === 'HOUR';
    },
  },
};
</script>

<style scoped>
.timeline-period {
  @apply flex flex-col justify-between items-center h-full;
  padding-top: 10px;
  padding-bottom: 10px;

  &__header {
    @apply flex flex-col items-center text-3xs leading-3 text-graphite-900;
  }

  &__popup {
    @apply w-20;
  }

  &__tooltip {
  }
}
</style>
