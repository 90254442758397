<template>
  <div class="reservations">
    <component
      :is="viewComponent"
      v-if="rentableItem"
      :rentable-item="rentableItem"
      v-model:view-settings="viewSettings"
      @select-item="handleSelectItem"
    >
      <template #header>
        <ReservationsHeader
          :rentableItem="rentableItem"
          :title="rentableItemTitle"
          @ready="handleHeaderReady"
          @edit="handleEdit"
        />
      </template>

      <template #toolbar v-if="!rentableItem.isFungible || hasPermission('RESERVATIONS_EDIT')">
        <div class="reservations__toolbar">
          <ToggleButtonsInput
            class="ml-6"
            with-icons
            :options="modeOptions"
            :value="viewSettings.mode"
            @change="handleChangeMode"
          />
          <button
            v-if="hasPermission('RESERVATIONS_EDIT')"
            class="btn-primary btn-solid btn-small ml-6"
            @click="handleCreateReservation"
          >
            make a new reservation
          </button>
        </div>
      </template>
    </component>

    <loader :loading="loading || headerLoading" :backdrop="true"/>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import NotifyMixin from '@/mixins/NotifyMixin';
import Loader from '@/components/ui/Loader';
import ModalNavigation from '@/mixins/ModalNavigation';
import ActionsMixin from "@/mixins/ActionsMixin";
import ReservationsHeader from "@/components/ri/reservations/ReservationsHeader";
import ReservationsTimeline from "@/components/ri/reservations/ReservationsTimeline";
import ReservationsList from "@/components/ri/reservations/ReservationsList";
import ReservationsToolbar from "@/components/ri/reservations/ReservationsToolbar";
import ToggleButtonsInput from "@/components/ui/ToggleButtonsInput";

export default {
  components: {
    ReservationsToolbar,
    ReservationsHeader,
    ToggleButtonsInput,
    Loader,
  },

  mixins: [NotifyMixin, ModalNavigation, ActionsMixin],

  data() {
    return {
      rentableItem: null,
      modeOptions: [],
      viewSettings: {},
      loading: true,
      headerLoading: true,
    };
  },

  computed: {
    ...mapGetters({
      hasPermission: 'ri/hasPermission',
      persistedValues: 'ri/persistedValues',
    }),

    rentableItemTitle() {
      let title = this.rentableItem.name;

      if (this.rentableItemAddress) {
        title += `, ${this.rentableItemAddress}`;
      }

      return title;
    },

    rentableItemAddress() {
      const {locationsChain, customLocation} = this.rentableItem;

      if (!customLocation && !locationsChain) {
        return '';
      }

      let address = locationsChain.reduce((acc, {name}, index) => {
        acc = `${acc} ${name}`;

        if (locationsChain.length - 1 !== index) {
          acc += ',';
        }

        return acc;
      }, '');

      if (customLocation) {
        address = address ? `${address}, ${customLocation}` : customLocation;
      }

      return address;
    },

    rentableItemId() {
      return this.$route.params.rentableItemId;
    },

    viewComponent() {
      switch (this.viewSettings.mode) {
        case 'TIMELINE':
          return ReservationsTimeline;

        case 'LIST':
          return ReservationsList;

        default:
          return null;
      }
    },
  },

  methods: {
    ...mapActions({
      persistValues: 'ri/persistValues',
    }),

    profileAddress(profile) {
      return this.$tc(
        profile.unitId === profile.apartmentId
          ? 'ri.unit_building'
          : 'ri.unit_apartment_building',
        profile
      );
    },

    handleHeaderReady() {
      this.headerLoading = false;
    },

    handleChangeMode(mode) {
      this.viewSettings.mode = mode;
    },

    handleEdit() {
      this.persistValues(this.viewSettings);

      this.$router.push({
        name: 'ri.edit',
        params: {
          rentableItemId: this.rentableItemId,
        },
      });
    },

    handleSelectItem(reservation) {
      if (!this.hasPermission('RESERVATIONS_VIEW')) {
        return;
      }

      this.persistValues(this.viewSettings);

      this.$router.push({
        name: 'ri.reservation.details',
        params: {
          rentableItemId: this.rentableItemId,
          reservationId: reservation.id,
        },
      });
    },

    handleCreateReservation() {
      this.$router.push({
        name: 'ri.createReservation',
        params: {
          rentableItemId: this.rentableItemId,
        },
      });
    },

    deleteRI() {
      this.loading = true;

      this.$riDataProvider.delete('rentableItems', {id: this.rentableItemId})
        .then(() => {
          this.close();
          this.notifySuccess('rentable item was successfully deleted');
        })
        .finally(() => this.loading = false);
    },
  },

  async mounted() {
    this.loading = true;

    try {
      const rentableItem = await this.$riDataProvider.getOne('rentableItems', {id: this.rentableItemId});
      this.rentableItem = rentableItem;
      this.setActiveModalName(this.rentableItemTitle);

      if (rentableItem.isFungible) {
        if (!this.viewSettings.mode) {
          this.viewSettings.mode = 'LIST';
        }
      } else {
        this.modeOptions = [
          {
            key: 'TIMELINE',
            value: 'timeline',
          },
          {
            key: 'LIST',
            value: 'list',
          }
        ];

        if (!this.viewSettings.mode) {
          this.viewSettings.mode = 'TIMELINE';
          this.viewSettings.timeslot = rentableItem.timeslot.unit;
        }
      }
    } catch (error) {
      this.notifyError(error.message);
    } finally {
      this.loading = false;
    }

    if (this.hasPermission('RI_EDIT')) {
      this.addConfirmAction(
        {
          id: 'ri-delete-ri',
          title: 'delete',
          showConfirmation: true,
          confirmationType: 'warning',
          confirmationMessage: 'are you sure you want to delete this rentable item? It won’t be observable on the Resident portal & Quext any more. All the reservations will be canceled. The action can’t be undone.',
          confirmBtnText: 'yes, delete',
          cancelBtnText: 'no, go back',
        },
        this.deleteRI,
      );
    }
  },

  beforeRouteEnter(to, from, next) {
    if (from.name === 'ri.reservation.details' || from.name === 'ri.edit') {
      next(vm => {
        if (vm.persistedValues) {
          vm.viewSettings = vm.persistedValues;
          vm.persistValues(null);
        }
      });
    } else {
      next();
    }
  },
}
</script>

<style scoped>
.reservations {
  @apply h-full;

  &__toolbar {
    @apply flex items-center;
    height: 2.25rem;
  }
}
</style>
