<template>
  <TimelineColumns
    class="timeline-row"
    :sidebar-class="`timeline-row__sidebar ${sidebarClass}`"
    :time-window="timeWindow"
  >
    <template #sidebar>
      <Tooltip
        class="timeline-row__name"
        show-on-overflow
      >
        <template #label>
          <span class="timeline-row__label">{{name}}</span>
        </template>

        <template #default>
          {{name}}
        </template>
      </Tooltip>
    </template>

    <template #content>
      <transition-group name="timeline-row__reservation">
        <TimelineReservation
          v-for="reservation in reservations"
          :key="reservation.id"
          :reservation="reservation"
          :time-window="timeWindow"
          @click="$emit('select-item', reservation)"
        />
      </transition-group>

      <TimelineToday :time-window="timeWindow" />
    </template>
  </TimelineColumns>
</template>

<script>
import TimelineReservation from "@/components/ri/reservations/TimelineReservation";
import TimelineColumns from "@/components/ri/reservations/TimelineColumns";
import TimelineToday from "@/components/ri/reservations/TimelineToday";
import Tooltip from "@/components/ui/Tooltip";

export default {
  components: {TimelineReservation, TimelineColumns, TimelineToday, Tooltip},

  props: {
    name: {
      type: String,
      required: true,
    },

    reservations: {
      type: Array,
      required: true,
    },

    timeWindow: {
      type: Object,
      required: true,
    },

    sidebarClass: {
      type: String,
      default: '',
    },
  },

  emits: ['select-item'],
};
</script>

<style scoped>
.timeline-row {
  /* purgecss start ignore */
  & :deep(&__sidebar) {
    @apply flex h-full items-center;
  }
  /* purgecss end ignore */

  &__name {
    @apply text-xs leading-4 h-8 truncate whitespace-normal max-w-full;
    display: flex;
    align-items: center;
    min-width: 0;
  }

  &__label {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }

  &__reservation {
    &-enter-from,
    &-leave-to {
      @apply opacity-0;
    }
  }
}
</style>
