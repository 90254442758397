<template>
  <FinalForm
    ref="form"
    class="h-full"
    :initial-values="initialValues"
    :submit="handleSubmit"
    @change.self="handleFormChange"
    :validate="validate"
  >
    <template v-slot="props">
      <form class="h-full" @submit="props.handleSubmit">
        <Wizard
          ref="steps"
          submit-button="publish on resident portal"
          @cancel="close"
        >
          <WizardPage title="1. main information">
            <div class="form-panel grid grid-cols-2 gap-8">
              <div>
                <div class="form-row">
                  <TextField
                    name="name"
                    label="name*"
                    :validate="composeValidators(required, minLength(2), maxLength(150))"
                  />
                </div>
                <div class="form-row">
                  <SelectInput
                    name="chargeCodeId"
                    label="charge code"
                    :options="chargeCodes"
                    option-key="id"
                    option-label="description"
                  />
                </div>
                <div class="form-row">
                  <TextField
                    name="description"
                    label="description for a resident*"
                    multiline
                    rows="8"
                    :validate="composeValidators(required, minLength(2), maxLength(5000))"
                  />
                </div>
              </div>
              <div>
                <div class="form-row">
                  <SelectInput
                    name="categoriesIds"
                    label="categories*"
                    hint="Select at least 1 & maximum 5 categories"
                    :options="categories"
                    option-key="id"
                    option-label="name"
                    multiple
                    :validate="composeValidators(notEmpty, maxLength(5))"
                  />
                </div>
              </div>
            </div>
          </WizardPage>

          <WizardPage
            title="2. basic parameters"
            class="flex min-h-full"
          >
            <div class="side-panel flex-shrink-0">
              <div class="side-panel-heading">rentable items types</div>
              <div class="side-panel-text mb-8"><strong>Asset</strong> - a physical object that can be rented for short or long periods of time (parking spaces, bikes, storage lockers, etc).</div>
              <div class="side-panel-text comment"><strong>Coming soon:</strong></div>
              <div class="side-panel-text comment"><strong>Location</strong> - a building, room, or common area that can be reserved or rented (clubhouse, pool, sport court, etc).</div>
              <div class="side-panel-text comment"><strong>Subscription/Service</strong> - convenience or participative offerings to residents that might be one-time or recurring (shuttle service, metro/subway passes, fitness memberships, etc).</div>
            </div>

            <div class="form-panel">
              <div class="row flex-col items-start">
                <AmountField
                  name="qty"
                  label="quantity*"
                  :precision="0"
                  :compute-width="false"
                  inputClass="flex"
                  class="qty"
                  v-bind="quantityProps"
                >
                  <template v-slot:after>
                    <div v-if="formValues.isFungible && formValues.qty < initialValues.qty" class="flex ml-4">
                      <Icon name="info" class="w-4 min-w-4 h-4 text-blue-600 mr-1" />
                      <span class="font-frank font-medium text-xs text-blue-800 leading-4">please note that as a result of reduction in number of assets some reservations need to be canceled</span>
                    </div>
                  </template>
                </AmountField>

                <router-link
                  v-if="rentableItemId && !formValues.isFungible"
                  class="btn-link"
                  :to="{name: 'ri.assets', params: {rentableItemId}}"
                >
                  view assets list
                </router-link>

                <div
                  v-if="rentableItemId"
                  class="form-col mt-6"
                >
                  <template v-if="formValues.isFungible">
                    <label>fungible assets</label>
                    <p class="font-inter text-xs text-black">
                      Offerings that are essentially indistinguishable from each other. For example, if you rent bicycles, one bicycle is probably considered just as good as another.
                    </p>
                  </template>

                  <template v-else>
                    <label>non-fungible assets</label>
                    <p class="font-inter text-xs text-black">
                      Offerings where there is an appreciable difference between each offering. For example, parking spaces tend to be non-fungible because the location of each parking space is often important to the renting person.
                    </p>
                  </template>
                </div>

                <div v-else class="mt-8">
                  <CheckboxInput
                    name="isFungible"
                    value="mark as fungible"
                  />
                  <div class="ml-4 text-xs text-black">Offerings that are essentially indistinguishable from each other. For example, if you rent bicycles, one bicycle is probably considered just as good as another.</div>
                  <div class="mt-2 font-frank text-xs font-medium">
                    <Icon name="info" class="w-3 h-3 mt-1 float-left text-active-700" />
                    <div class="ml-4 text-active-800">please note upon rentable item creation you won’t be able to edit this parameter</div>
                  </div>
                </div>
              </div>

              <div class="row flex-col">
                <div class="font-frank font-bold text-sm text-black">time slot setting</div>
                <div class="mt-4 grid grid-cols-2 gap-x-4">
                  <div class="grid grid-cols-2 gap-x-2 items-end">
                    <AmountField
                      name="timeslot.min"
                      label="minimum time slot*"
                      :validate="required"
                      textClass=""
                      precision="0"
                      :compute-width="false"
                      suppress-error
                      disabled
                    />
                    <SelectInput
                      name="timeslot.unit"
                      :options="timeUnits"
                      no-clear
                      :disabled="rentableItemId"
                    />
                  </div>
                  <div class="flex items-end w-1/2">
                    <AmountField
                      name="timeslot.rate"
                      label="rate per minimum time slot*"
                      prefix="$"
                      :min="0"
                      :max="999999.99"
                      :compute-width="false"
                      :validate="required"
                      suppress-error
                    />
                  </div>
                  <div>
                    <FieldError name="minTimeSlot" class="mr-2" hint="Basic time slot a reservation can consist of" />
                  </div>
                  <div class="w-1/2">
                    <FieldError name="rate" class="mr-2" />
                  </div>
                </div>
                <div v-if="!rentableItemId" class="mt-2 font-frank text-xs font-medium">
                  <Icon name="info" class="w-3 h-3 mt-1 float-left text-active-700" />
                  <div class="ml-4 text-active-800">please note upon rentable item creation you won’t be able to edit minimum time slot parameter</div>
                </div>
              </div>

              <div class="row flex-col">
                <div class="font-frank font-bold text-sm text-black mb-2">location</div>
                <div class="grid grid-cols-2 gap-6">
                  <template
                    v-for="(item, index) of locationItems"
                    :key="index"
                  >
                    <TextField
                      v-if="item.isCustom"
                      :name="item.name"
                      :label="item.label"
                      :validate="item.validate"
                    />
                    <LocationSelect
                      v-else
                      :key="item.parentId"
                      :parent-id="item.parentId"
                      :label="item.label"
                      :name="item.name"
                      :validate="item.validate"
                    />
                  </template>
                </div>
              </div>
            </div>
          </WizardPage>

          <WizardPage title="3. availability schedule">
            <div class="flex justify-between">
              <div class="font-frank text-sm font-bold mr-4">
                {{availabilityScheduleTitle}}
              </div>
              <div class="flex items-center">
                <ValidationMessage v-if="equalToCommunitytSchedule" text="Community working hours applied" type="success" />
                <InfoText v-if="!hasCommunitySchedule" class="text-blue-800 font-medium font-frank text-xs">working hours are not set up for the community</InfoText>
                <div v-if="hasCommunitySchedule && !equalToCommunitytSchedule" class="flex items-center">
                  <tooltip icon="info" :css="{tooltipPopup: 'w-72'}">
                    <ScheduleTooltipContent
                      :schedule="groupScheduleByDays(communitySchedule)"
                      :days="days"
                    />
                  </tooltip>
                  <button type="button" class="ml-4 btn-primary" @click="applyCommunitySchedule">
                    reset to community working hours
                  </button>
                </div>
              </div>
            </div>

            <div class="mt-6">
              <div
                :key="index"
                v-for="({ working24 }, index) in formValues.availabilitySchedule"
                class="flex items-start justify-between mt-6"
              >

                <div class="w-1/4">
                  <div>
                    <SelectInput
                      placeholder="Select"
                      :name="`availabilitySchedule[${index}].days`"
                      label="day(s)*"
                      :options="daysOptions"
                      :validate="required"
                      noClear
                    />
                  </div>
                  <span
                    class="form-error"
                    v-if="formErrors?.validAvailabilitySchedule?.rows?.includes(index)"
                  >
                    {{ formErrors.validAvailabilitySchedule.message }}
                  </span>
                </div>

                <div class="ml-6">
                  <div class="flex">
                      <div class="mr-6">
                        <TimePickerField
                          :name="`availabilitySchedule[${index}].openTime`"
                          label="open time"
                          :disabled="working24"
                        />
                      </div>
                      <TimePickerField
                        :name="`availabilitySchedule[${index}].closeTime`"
                        label="close time"
                        :disabled="working24"
                      />
                  </div>
                  <span
                    class="form-error"
                    v-if="formErrors?.validTime?.rows?.includes(index)"
                  >
                    {{ formErrors.validTime.message }}
                  </span>
                  <span
                    class="form-error"
                    v-if="formErrors?.notEqualTime?.rows?.includes(index)"
                  >
                    {{ formErrors.notEqualTime.message }}
                  </span>
                  <span
                    class="form-error"
                    v-if="formErrors?.emptyAvailabilitySchedule && index === lastChangedTimeRow"
                  >
                    {{ formErrors.emptyAvailabilitySchedule }}
                  </span>
                </div>
                <div class="ml-6">
                  <ToggleField
                    :name="`availabilitySchedule[${index}].working24`"
                    label="24 hours"
                    class="w-24"
                    :inputLabel="working24 ? 'yes' : 'no'"
                  />
                </div>

                <div class="flex flex-col justify-center mt-3 ml-6">
                  <icon
                    name="plusCircle"
                    class="w-4 h-4 min-w-4 text-active-500 cursor-pointer mb-2"
                    @click="addRow(index)"
                  />
                  <icon
                    v-if="index !== 0"
                    name="minusCircle"
                    class="w-4 h-4 min-w-4 text-graphite-800 cursor-pointer"
                    @click="removeRow(index)"
                  />
                </div>
              </div>
            </div>
          </WizardPage>
        </Wizard>
      </form>
      <loader :loading="loading" :backdrop="true" />
    </template>
  </FinalForm>
</template>

<script>
import {cloneDeep, isEqual} from 'lodash-es';
import { FinalForm } from "vue-final-form";
import ModalNavigation from "@/mixins/ModalNavigation";
import NotifyMixin from "@/mixins/NotifyMixin";
import InitializeFormMixin from "@/components/form/InitializeFormMixin";
import ValidatorMixin from "@/components/form/ValidatorMixin";
import ConfirmationMixin from "@/mixins/ConfirmationMixin";
import Wizard from "@/components/ui/wizard/Wizard";
import WizardPage from "@/components/ui/wizard/WizardPage";
import Loader from "@/components/ui/Loader";
import TextField from "@/components/form/TextField";
import SelectInput from "@/components/form/SelectInput";
import CheckboxInput from "@/components/form/CheckboxInput";
import AmountField from "@/components/form/AmountField";
import FieldError from "@/components/form/FieldError";
import ToggleField from "@/components/form/ToggleField";
import TimePickerField from "@/components/form/TimePickerField";
import ValidationMessage from "@/components/ui/ValidationMessage";
import Icon from "@/components/ui/Icon";
import LocationSelect from "@/components/ri/LocationSelect";
import Tooltip from "@/components/ui/Tooltip";
import InfoText from "@/components/ui/InfoText";
import ScheduleTooltipContent from "@/components/ri/ScheduleTooltipContent";
import {
  groupScheduleByDays,
  prepareSchedule,
  parseSchedule,
} from "@/utils/Schedule";
import {initialAvailabilitySchedule, initialTimeUnits} from '@/views/ri/constants';
import {createNamespacedHelpers} from "vuex";

const {mapActions, mapGetters} = createNamespacedHelpers('ri');

export default {
  components: {
    Wizard,
    WizardPage,
    FinalForm,
    Loader,
    TextField,
    SelectInput,
    CheckboxInput,
    AmountField,
    LocationSelect,
    FieldError,
    Icon,
    ValidationMessage,
    Tooltip,
    ToggleField,
    TimePickerField,
    InfoText,
    ScheduleTooltipContent
  },

  mixins: [
    ModalNavigation,
    ValidatorMixin,
    NotifyMixin,
    InitializeFormMixin,
    ConfirmationMixin,
  ],

  data() {
    return {
      initialValues: {
        qty: 1,
        isFungible: false,
        timeslot: {
          unit: null,
          min: 1,
          rate: 0,
        },
        locationsChain: [],
        customLocation: null,
        availabilitySchedule: initialAvailabilitySchedule,
      },
      communitySchedule: [],
      categories: [],
      chargeCodes: [],
      timeUnits: cloneDeep(initialTimeUnits),
      days: null,
      formValues: null,
      formErrors: null,
      loading: false,
      formInitialized: false,
      lastChangedTimeRow: null,
    };
  },

  computed: {
    ...mapGetters([
      'persistedValues',
    ]),

    locationItems() {
      const chainLength = this.formValues.locationsChain.length;
      return Array.from({length: chainLength + 1}, (_, index) => {
        const parentId = this.formValues.locationsChain[index - 1]?.id ?? '';
        const isCustom = parentId === 'CUSTOM';
        const isRequired = isCustom;
        return {
          parentId,
          isCustom,
          label: `level ${index + 1}${isRequired ? '*' : ''}`,
          name: isCustom ? 'customLocation' : `locationsChain.${index}`,
          validate: isRequired ? this.required : undefined,
        };
      });
    },

    daysOptions() {
      return this.days ? this.days.map((daysItem, index) => ({
        key: index,
        value: daysItem,
      })) : [];
    },

    hasCommunitySchedule() {
      return Array.isArray(this.communitySchedule) && this.communitySchedule.length;
    },

    equalToCommunitytSchedule() {
      return isEqual(this.formValues.availabilitySchedule, this.communitySchedule);
    },

    availabilityScheduleTitle() {
      return this.hasCommunitySchedule ? 'use community working hours or make changes to create a custom schedule' : 'create custom schedule';
    },

    rentableItemId(){
      return this.$route.params.rentableItemId;
    },

    quantityProps(){
      if(this.rentableItemId && !this.formValues.isFungible){
        return {
          textClass: "text-black inline-block mr-2",
          editMode: false,
        };
      }

      return {
        textClass: "",
        validate: this.composeValidators(this.minValue(1), this.maxValue(9999)),
      };
    },
  },

  methods: {
    ...mapActions([
      'persistValues',
    ]),

    groupScheduleByDays,

    addRow(index) {
      const { openTime, closeTime, working24 } =
        this.formValues.availabilitySchedule[index];

      this.$refs.form.finalForm.change("availabilitySchedule", [
        ...this.formValues.availabilitySchedule,
        {
          days: null,
          openTime,
          closeTime,
          working24,
        },
      ]);
    },

    removeRow(index) {
      this.$refs.form.finalForm.change("availabilitySchedule", [
        ...this.formValues.availabilitySchedule.slice(0, index),
        ...this.formValues.availabilitySchedule.slice(index + 1),
      ]);
    },

    handleFormChange({values, errors}) {
      this.formValues = values;
      this.formErrors = errors;
    },

    handleSubmit(values) {
      if (this.loading) {
        return;
      }

      switch (this.$refs.steps.currentStep) {
        case 0:
          if(this.rentableItemId){
            return this.$refs.steps.goNext();
          }

          this.loading = true;

          return this.$riDataProvider.create('riNameCheck', {data: {name: this.formValues.name}})
            .then(() => this.$refs.steps.goNext())
            .catch(e => ({name: e.message}))
            .finally(() => { this.loading = false; });
        case 1:
          return this.$refs.steps.goNext();

        case 2: {
          if(!this.formValues.isFungible || !this.rentableItemId) {
            return this.submitData(values);
          }

          this.loading = true;

          return this.$riDataProvider.create('riReservationsToCancel', {riId: this.rentableItemId, data: { qty: values.qty }})
            .then(({ count }) => {
              if(count){
                const reservationLabel = this.$tc('ri.reservation', count);

                this.requestConfirmation({
                  confirmationMessage: `are you sure you want to apply changes? As a result of asset reduction ${count} ${reservationLabel} need to be cancelled. You can do it on yourself by closing the window & selecting the reservations from the list. Or we can do it for you by cancelling the most recently created reservations`,
                  confirmBtnText: `yes, apply changes & cancel ${reservationLabel}`,
                  cancelBtnText: 'no, go back',
                  confirmationType: 'warning'
                })
                .then((confirmed) => {
                  if(confirmed){
                    this.submitData(values);
                  }
                });
              } else {
                this.submitData(values);
              }
            })
            .catch(error => this.notifyError(error.message))
            .finally(() => { this.loading = false; });
        }
      }
    },

    async submitData(rawData) {
      this.loading = true;

      const {locationsChain, ...rest} = rawData;
      const locationId = locationsChain.findLast(loc => loc.id !== 'CUSTOM')?.id;

      const data = {
        ...rest,
        locationId,
        availabilitySchedule: prepareSchedule(rawData.availabilitySchedule, this.days),
      };

      try {
        if(this.rentableItemId){
          await this.$riDataProvider.update("rentableItems", { id: this.rentableItemId, data })
          this.notifySuccess('The rentable item was successfully updated');
        } else {
          await this.$riDataProvider.create("rentableItems", { data })
          this.notifySuccess('The rentable item was successfully created');
        }

        this.close();
      }
      catch (error){
        this.notifyError(error.message);
      }
      finally {
        this.loading = false;
      }
    },

    fetchRentableItem(){
      return this.$riDataProvider.getOne('rentableItems', {id: this.rentableItemId})
        .then(rentableItem => {
          const {
            categories,
            availabilitySchedule : riAvailabilitySchedule,
            chargeCode: {id : chargeCodeId},
            locationsChain,
            ...rest
          } = rentableItem;

          const categoriesIds = categories.map(({id}) => id);

          const availabilitySchedule = riAvailabilitySchedule.length
            ? parseSchedule(riAvailabilitySchedule, this.days)
            : this.initialValues.availabilitySchedule;

          const initialValues = {
            categoriesIds,
            availabilitySchedule,
            chargeCodeId,
            locationsChain: locationsChain.reverse(),
            ...rest,
          };

          if (!this.formInitialized) {
            this.initialValues = initialValues;
            this.formInitialized = true;
          } else {
            this.$refs.form.finalForm.change('qty', rentableItem.qty);
          }

          this.setActiveModalTitle(`edit ${rentableItem.name.toLowerCase()}`);
        });
    },

    fetchCategories(){
      this.$riDataProvider.getList('categories').then(categories => {
        this.categories = categories;
      });
    },

    fetchCommunitySchedule(){
      this.$riDataProvider.getList('communitySchedule')
        .then(communitySchedule => {
          this.communitySchedule = parseSchedule(communitySchedule, this.days);

          if(this.hasCommunitySchedule){
            this.$refs.form.finalForm.change('availabilitySchedule', this.communitySchedule);
          }
        });
    },

    fetchChargeCodes(){
      this.$riDataProvider.getList('chargeCodes').then(chargeCodes => {
        this.chargeCodes = chargeCodes;
      });
    },

    fetchTimeLimits(){
      this.$riDataProvider.getList('timeUnits').then(timeUnits => {
        this.timeUnits.forEach(unit => {
          unit.$isDisabled = !timeUnits.includes(unit.key);
        });

        if (!this.rentableItemId) {
          this.$refs.form.finalForm.change('timeslot.unit', timeUnits.findLast(unit => !unit.$isDisabled));
        }
      });
    },

    fetchDays(){
      return this.$riDataProvider.getList('weekdays');
    },

    applyCommunitySchedule(){
      this.$refs.form.finalForm.change("availabilitySchedule", this.communitySchedule);
    },

    validate({ availabilitySchedule }){
      const scheduleAccumulation = groupScheduleByDays(availabilitySchedule);

      if(this.$refs.steps?.currentStep === 2){
        return {
          validAvailabilitySchedule: this.validSchedule(scheduleAccumulation),
          validTime: this.validTime(availabilitySchedule),
          notEqualTime: this.notEqualTime(availabilitySchedule),
          emptyAvailabilitySchedule: this.notEmptySchedule(availabilitySchedule),
        };
      }

      return {};
    },

    initializeWatchers(){
      this.$watch('formValues.availabilitySchedule', (newSchedule, oldSchedule) => {
        newSchedule.forEach((row, index) => {
          if (row.working24 && !isEqual(newSchedule[index], oldSchedule[index])) {
            row.openTime = {hours: '12', minutes: '00', clock: 'am'};
            row.closeTime = {hours: '12', minutes: '00', clock: 'am'};

            this.$refs.form.finalForm.change(`availabilitySchedule[${index}]`, row);
          }
        });

        if(newSchedule.length !== oldSchedule.length){
          const lastAvailableIndex = newSchedule.length - 1;

          if(lastAvailableIndex < this.lastChangedTimeRow){
            this.lastChangedTimeRow = lastAvailableIndex;
          } else {
            return;
          }
        }

        const changedRowIndex = newSchedule.findIndex(({working24}, index) => working24 !== oldSchedule[index].working24);

        if(changedRowIndex >= 0){
          this.lastChangedTimeRow = changedRowIndex;
        }
      });
    },

    initLocationsWatcher(){
      this.$watch('formValues.locationsChain', (newChain, oldChain) => {
        for (let i = 0; i < newChain.length; i++) {
          if (!newChain[i] || newChain[i] !== oldChain[i]) {
            this.$refs.form.finalForm.change('customLocation', null);
            this.$refs.form.finalForm.change('locationsChain', newChain.slice(0, i + 1).filter(loc => loc));
            break;
          }
        }
      });
    },
  },

  mounted() {
    this.formValues = this.initialValues;

    this.loading = true;

    this.initializeWatchers();

    if(!this.rentableItemId){
      this.initLocationsWatcher();
    }

    Promise.all([
      this.fetchCategories(),
      this.fetchChargeCodes(),
      this.fetchTimeLimits(),
      this.fetchDays().then(days => {
        this.days = days;
      }),
    ])
      .then(() => {
        if(this.days.length){
          return this.fetchCommunitySchedule();
        }
      })
      .then(() => {
        if(this.rentableItemId){
            return this.fetchRentableItem()
              .finally(() => this.initLocationsWatcher());
        }
      })
      .catch(error => this.notifyError(error.message))
      .finally(() => this.loading = false);
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'ri.assets') {
      this.persistValues(this.formValues);
    }

    next();
  },

  beforeRouteEnter(to, from, next) {
    if (from.name === 'ri.assets') {
      next(vm => {
        if (vm.persistedValues) {
          vm.initialValues = vm.persistedValues;
          vm.formInitialized = true;
          vm.persistValues(null);
          vm.$refs.steps.setStep(1);
        }
      });
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.form-panel {
  @apply flex-grow overflow-auto pb-4;
}

.row {
  @apply flex;
}

.row + .row {
  @apply mt-8;
}

.form-panel :deep(.form-col) {
  @apply mx-0 flex-shrink-0;
}

.side-panel-text.comment {
  @apply text-graphite-900;
}

.qty :deep(input) {
  width: 140px;
  min-width: 140px;
}
</style>
