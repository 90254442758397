<template>
  <div class="reservations-ribbon">
    <TimelineColumns
      class="reservations-ribbon__container"
      :sidebar-class="sidebarClass"
      :time-window="timeWindow"
    >
      <template #sidebar>
        <div class="reservations-ribbon__sidebar">
          <!--<Dropdown
            :value="timeslot"
            :options="timeslotOptions"
            :searchable="false"
            centered
            no-clear
            no-decoration
            @input="$emit('update:timeslot', $event)"
          />-->
          <div class="capitalize text-2sm font-medium text-black">{{$t(`ri.periods.${timeslot}`)}}</div>
          <div class="reservations-ribbon__nav-buttons">
            <IconButton class="reservations-ribbon__nav-button" icon="chevronLeft" @click="$emit('prev')" />
            <IconButton class="reservations-ribbon__nav-button" icon="chevronRight" @click="$emit('next')" />
          </div>
        </div>
      </template>

      <template #period="{period}">
        <TimelinePeriod
          :timeslot="timeslot"
          :period="period"
          :stats="availabilityStats[momentToLocalIso(period.startDate)]"
        />
      </template>

      <template #overlay>
        <TimelineToday
          :time-window="timeWindow"
          pointer
        />
      </template>
    </TimelineColumns>
  </div>
</template>

<script>
import IconButton from "@/components/ui/IconButton";
import TimelineColumns from "@/components/ri/reservations/TimelineColumns";
import TimelineToday from "@/components/ri/reservations/TimelineToday";
import TimelinePeriod from "@/components/ri/reservations/TimelinePeriod";
import {momentToLocalIso} from "@/components/ri/reservations/timeline";

export default {
  components: {IconButton, TimelineColumns, TimelineToday, TimelinePeriod},

  setup() {
    return {
      momentToLocalIso,
    };
  },

  props: {
    timeslot: {
      type: String,
      required: true,
    },

    timeslotOptions: {
      type: Array,
      required: true,
    },

    timeWindow: {
      type: Object,
      required: true,
    },

    availabilityStats: {
      type: Object,
      required: true,
    },

    availabilityNow: {
      type: Object,
      required: true,
    },

    sidebarClass: {
      type: String,
      default: '',
    },
  },

  emits: ['prev', 'next', 'update:timeslot'],
};
</script>

<style scoped>
.reservations-ribbon {
  @apply bg-white pt-4 -mt-4 overflow-hidden;

  &__container {
    @apply h-20 bg-graphite-100 border border-b-0 border-graphite-500;
  }

  &__sidebar {
    @apply flex flex-col h-full items-center justify-center;
  }

  &__nav-buttons {
    @apply mt-2;
  }

  &__nav-button {
    @apply text-graphite-800;
  }
}
</style>
