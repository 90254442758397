<template>
  <ul class="data-chain">
    <li
      class="data-chain__item"
      v-bind:key="id"
      v-for="{ id, name } in leadingItems"
    >
      <span class="data-chain__label">
        {{name}}
      </span>

      <icon
        v-if="trailingItems.length"
        name="chevronDown"
        class="data-chain__icon"
      />
    </li>

    <li v-if="hiddenItems.length" class="data-chain__item">
      <Tooltip
        label="..."
        :css="{tooltipPopup: 'w-auto'}"
        class="data-chain__hidden-label"
      >
        <ul class="data-chain">
          <li
            class="data-chain__item"
            v-bind:key="id"
            v-for="({ id, name }, index) in hiddenItems"
          >
             <span class="data-chain__label">
              {{name}}
            </span>
            <icon
              v-if="index < hiddenItems.length - 1"
              name="chevronDown"
              class="data-chain__icon"
            />
          </li>
        </ul>
      </Tooltip>
    </li>

    <li
      class="data-chain__item"
      v-bind:key="id"
      v-for="({ id, name }, index) in trailingItems"
    >
      <icon
        v-if="hiddenItems.length"
        name="chevronDown"
        class="data-chain__icon"
      />

      <template v-if="index === 0 || index === data.length - 1">
        <span class="data-chain__label">
          {{name}}
        </span>
      </template>
    </li>
  </ul>
</template>

<script>
import Icon from "@/components/ui/Icon";
import Tooltip from "@/components/ui/Tooltip";

export default {
  components: {
    Tooltip,
    Icon,
  },

  props: {
    /**
     * Array of items in `{key, name}` format
     */
    data: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    leadingItems(){
      return this.data.slice(0, 1);
    },
    trailingItems(){
      return  this.data.length > 1 ? this.data.slice(-1) : [];
    },
    hiddenItems(){
      return this.data.slice(1, this.data.length - 1);
    },
  },
};
</script>

<style scoped>
.data-chain {
  @apply flex;
}

.data-chain__label {
  @apply text-sm text-black font-inter tracking-wider;
}

.data-chain__icon {
  @apply inline-block w-4 h-4 text-graphite-800 transform rotate-90;
  margin-right: 0.15rem;
}

.data-chain__item {
  @apply flex items-center text-sm;
}

.data-chain__hidden-label {
  @apply text-graphite-800 text-sm font-inter;
}
</style>
