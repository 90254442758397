import moment from "moment-timezone";

function timeInRange(m, startDate, endDate) {
    return m.isBetween(startDate, endDate, undefined, '[]');
}

export function timelinePosition(timeWindow, timestamp) {
    const {periods} = timeWindow;
    const m = moment(timestamp);
    const periodIndex = periods.findIndex(period => timeInRange(m, period.startDate, period.endDate));

    if (periodIndex >= 0) {
        const period = periods[periodIndex];
        const periodWidth = 100 / periods.length;
        return (periodIndex + m.diff(period.startDate) / period.endDate.diff(period.startDate)) * periodWidth;
    }

    const {startDate, durationMillis} = timeWindow;
    return m.diff(startDate) / durationMillis * 100;
}

export function mergeReservations(oldReservations, newReservations, timeWindow) {
    const {startDate, endDate} = timeWindow;

    const oldReservationSet = Object.fromEntries(oldReservations.map(item => [item.id, item]));
    const newReservationSet = Object.fromEntries(newReservations.map(item => [item.id, item]));

    return Object
        .values(Object.assign(oldReservationSet, newReservationSet))
        .filter(reservation => newReservationSet[reservation.id] || startDate.isAfter(reservation.end) || endDate.isBefore(reservation.start));
}

export function momentToLocalIso(m) {
    return m.format('YYYY-MM-DDTHH:mm:ss');
}
