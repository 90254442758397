<template>
  <FinalForm
    v-if="reservation"
    ref="form"
    class="flex flex-col h-full text-sm"
    :initial-values="reservation"
  >
    <FinalField name="profile">
      <template v-slot="{value}">
        <div class="flex justify-between pb-4 border-b border-graphite-300 text-black mb-4">
          <div class="whitespace-no-wrap font-frank font-bold w-5/12">
            {{value.firstName}} {{value.lastName}}
          </div>
          <div class="flex justify-end w-5/12">
            <BuildingUnitDisplay
              class="max-w-full"
              :building-name="value.buildingName"
              :apartment-name="value.apartmentName"
            />
          </div>
        </div>
      </template>
    </FinalField>
    <div class="flex-1 flex">
      <div class="w-1/3 flex flex-col text-black bg-graphite-200 p-4">
        <div class="font-frank font-bold mb-4">personal data</div>
        <div class="mb-4">
          <TextField
            name="profile.contactEmails.0"
            label="email"
            :edit-mode="false"
          />
        </div>
        <div class="mb-4">
          <PhoneInput
            name="profile.contactPhones.0"
            label="phone"
            :edit-mode="false"
          />
        </div>

        <div class="font-frank font-bold mt-8 mb-4">date range</div>
        <div class="relative mb-6 pl-8">
          <DateInput
            name="start"
            label="start date"
            text-format="ll, hh:mm a"
            :edit-mode="false"
          />
          <div class="line" />
        </div>
        <div class="relative mb-6 pl-8">
          <DateInput
            name="end"
            label="end date"
            text-format="ll, hh:mm a"
            :edit-mode="false"
          />
        </div>

        <FinalField name="status">
          <template v-slot="{value}">
            <ReservationStatus :status="value" />
          </template>
        </FinalField>

        <div v-if="hasCancelledStatus && cancellationInfoMessage" class="flex mt-4">
          <Icon name="info" class="w-4 min-w-4 h-4 text-blue-600 mr-1" />
          <span class="font-frank font-medium text-xs text-blue-800">{{ cancellationInfoMessage }}</span>
        </div>

        <div class="flex-grow" />

        <div class="mt-4 text-graphite-900 text-2xs">
          <div>Created by {{reservation.createdBy}}</div>
          <div><CommunityDateTimeDisplay :input="reservation.createdAt" format="ll, hh:mm a"/></div>
        </div>
      </div>
      <div class="w-2/3 pl-8">
        <div class="flex py-4">
          <img class="amenity-image" :src="require('@/assets/modules/NA.png')" />

          <div>
            <div>
              <TextField
                name="ri.name"
                input-class="font-bold font-frank text-sm leading-4 mb-1 text-black"
                :edit-mode="false"
              />
            </div>
            <div>
              <TextField
                v-if="reservation.asset.name"
                name="asset.name"
                input-class="font-bold font-frank text-sm leading-4 mb-1 text-black"
                :edit-mode="false"
              />
              <div v-else class="mb-5" />
            </div>
            <div class="text-2xs leading-3 mb-2" v-if="reservation.ri.locationsChain.length">
              {{reservation.ri.locationsChain[0].type.toLowerCase()}} {{reservation.ri.locationsChain[0].name}}
            </div>
            <div class="mb-5" v-else />
            <div class="flex justify-start">
              <AmountInput
                :value="reservation.price.rate"
                prefix="$"
                amount-class="font-inter text-2sm leading-4 font-semibold text-black"
                read-only
              />
              <span class="font-inter text-2sm leading-4 ml-1 text-black"> /{{reservation.price.unit.toLowerCase() || 'month'}}</span>
            </div>
          </div>
        </div>

        <div class="my-6 border-b border-graphite-300" />

        <div class="py-4">
          <div class="text-sm font-frank font-bold text-black mb-6">payment</div>

          <div>
            <AmountField
              name="price.cost"
              :label="paymentTerms"
              prefix="$"
              text-class="text-left"
              :edit-mode="false"
            />
          </div>
        </div>
      </div>
    </div>
  </FinalForm>
  <Loader :loading="loading" backdrop />
</template>

<script>
import {mapGetters} from "vuex";
import {FinalForm, FinalField} from "vue-final-form";
import TextField from "@/components/form/TextField";
import PhoneInput from "@/components/form/PhoneInput";
import NotifyMixin from "@/mixins/NotifyMixin";
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import Icon from "@/components/ui/Icon";
import AmountInput from "@/components/ui/AmountInput";
import CommunityDateTimeDisplay from "@/components/ui/CommunityDateTimeDisplay";
import AmountField from "@/components/form/AmountField";
import DateInput from "@/components/form/DateInput";
import ActionsMixin from "@/mixins/ActionsMixin";
import ReservationStatus from "@/components/ri/ReservationStatus";
import BuildingUnitDisplay from "@/components/ui/BuildingUnitDisplay";
import { cancelTypes } from '@/views/ri/constants';

export default {
  mixins: [
    ModalNavigation,
    NotifyMixin,
    ActionsMixin
  ],

  components: {
    BuildingUnitDisplay,
    Loader,
    FinalForm,
    FinalField,
    TextField,
    PhoneInput,
    DateInput,
    AmountInput,
    AmountField,
    CommunityDateTimeDisplay,
    ReservationStatus,
    Icon,
  },

  data() {
    return {
      reservation: null,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      hasPermission: 'ri/hasPermission',
    }),

    rentableItemId() {
      return this.$route.params.rentableItemId;
    },

    reservationId() {
      return this.$route.params.reservationId;
    },

    paymentTerms() {
      const qty = this.reservation.price.qty || 1;
      const duration = this.reservation.price.duration || 1

      const assets = qty.toString() + ' ' + this.$tc('ri.asset', qty);
      const periods = duration.toString() + ' ' + this.$tc(`ri.periods.${this.reservation.price.unit}`, duration);

      return `${assets} x ${periods}`;
    },

    hasCancelledStatus(){
      return this.reservation.status === 'CANCELED';
    },

    cancellationInfoMessage(){
      switch(this.reservation.cancelType){
        case cancelTypes.ASSET_DELETED:
        case cancelTypes.RI_QTY_DECREASED:
          return `the reservation was canceled because the ${this.$tc('ri.asset', this.reservation.price.qty)} ${this.$tc('ri.asset_deleted', this.reservation.price.qty)} by community`;
        default:
          return '';
      }
    },
  },

  async mounted() {
    this.loading = true;

    await this.$riDataProvider.getOne('reservations', {
      riId: this.rentableItemId,
      id: this.reservationId,
    })
      .then(reservation => {
        this.reservation = reservation;
      })
      .catch(error => {
        this.notifyError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });

    if (this.hasPermission('RESERVATIONS_EDIT') && !this.hasCancelledStatus) {
      this.addConfirmAction(
        {
          id: 'ri-cancel-reservation',
          title: 'cancel reservation',
          showConfirmation: true,
          confirmationType: 'warning',
          confirmationMessage: 'are you sure you want to cancel the reservation?',
          confirmBtnText: 'yes, cancel reservation',
          cancelBtnText: 'no, go back',
        },
        this.cancelReservation,
      );
    }
  },

  methods: {
    cancelReservation() {
      this.loading = true;

      this.$riDataProvider.cancel('reservations', {
        riId: this.rentableItemId,
        data: [this.reservationId],
      })
        .then(reservation => {
          this.reservation = reservation;
          this.$refs.form.finalForm.reset(this.reservation);
          this.removeConfirmAction('ri-cancel-reservation');
          this.notifySuccess('Reservation canceled');
        })
        .catch(error => {
          this.notifyError(error.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.line {
  @apply absolute border-l border-active-500 border-dotted;
  height: calc(100% + 1.5rem);
  left: 11px;
  top: 50%;
}

.line:before,
.line:after {
  content: "";
  @apply absolute border rounded border-active-500 bg-white;
  width: 7px;
  height: 7px;
  left: -4px;
  transform: translateY(-50%);
  z-index: 1;
}

.line:before {
  top: 0;
}

.line:after {
  @apply bg-active-500;
  top: 100%;
}

.amenity-image {
  width: 100px;
  height: 78px;
  object-fit: cover;
  @apply mr-5;
}
</style>
