<template>
  <Tooltip :should-prevent="shouldPrevent">
    <template v-slot:label>
      <div class="building-unit-display" ref="content">
        <div class="building-unit-display__element font-medium">{{ buildingName }}</div>
        <div class="mr-2 font-medium">:</div>
        <div class="building-unit-display__element">{{ apartmentName }}</div>
      </div>
    </template>

    <template v-slot:default>
      <span class="font-frank font-normal">
        <span class="font-medium">{{ buildingName }}:</span> {{ apartmentName }}
      </span>
    </template>
  </Tooltip>
</template>

<script>
import Tooltip from "@/components/ui/Tooltip";

export default {
  components: {Tooltip},

  props: {
    buildingName: {
      type: String,
      required: true,
    },

    apartmentName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      showTooltip: true,
    };
  },

  methods: {
    shouldPrevent() {
      return Array.from(this.$refs.content.children).every(el => el.scrollWidth <= el.clientWidth);
    },
  },
};
</script>

<style scoped>
.building-unit-display {
  @apply font-frank font-normal flex min-w-0;
}

.building-unit-display__element {
  @apply block truncate min-w-0;
}
</style>
