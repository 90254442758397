<template>
  <FinalField :name="name" :validate="validate">
    <template v-slot="props">
      <div>
        <label v-if="label" :for="name">{{label}}</label>

        <TimePickerInput
          v-if="editMode"
          :value="props.value"
          :name="props.name"
          @time-picked="val => props.change(val)"
          v-bind="$attrs"
        />

        <FieldError v-if="!suppressError" :name="name" />
      </div>
    </template>
  </FinalField>
</template>
<script>
import {FinalField} from 'vue-final-form';
import TimePickerInput from "@/components/ui/TimePickerInput";
import FieldError from "@/components/form/FieldError";

export default {
  components: {
    FinalField,
    TimePickerInput,
    FieldError,
  },

  inheritAttrs: false,

  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    validate: {
      type: [Function, Array],
      required: false,
    },
    editMode: {
      type: Boolean,
      default: true,
    },
    suppressError: {
      type: Boolean,
      required: false,
      default: false
    },
  },
}
</script>

<style scoped>

label {
  @apply text-black text-xs font-600 font-inter block select-none tracking-wide lowercase mb-2;
}

</style>
