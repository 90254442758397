<template>
  <div class="reservations-timeline">
    <slot name="header"/>

    <ReservationsToolbar>
      <template #main>
        <button class="btn-primary btn-xs mr-6" @click="resetStartDate">today</button>
        <div class="font-frank font-bold text-sm text-black mr-6">{{timeWindowLabel.toLowerCase()}}</div>
        <div class="flex-1 flex justify-end text-3xs text-black">
          <div class="flex items-center"><AssetsUnavailableIcon class="ml-4 mr-1"/> fully rented</div>
          <div class="flex items-center"><AssetsAvailableIcon class="ml-4 mr-1"/> fully available</div>
          <div class="flex items-center"><AssetReservationEndsIcon class="ml-4 mr-1"/> reservation ends</div>
          <div class="flex items-center"><AssetReservationStartsIcon class="ml-4 mr-1"/> reservation starts</div>
        </div>
      </template>

      <template #right>
        <slot name="toolbar"/>
      </template>
    </ReservationsToolbar>

    <ReservationsRibbon
      class="reservations-timeline__ribbon"
      sidebar-class="reservations-timeline__sidebar"
      :time-window="timeWindow"
      :availability-stats="availabilityStats"
      :availability-now="availabilityNow"
      :timeslot="viewSettings.timeslot"
      :timeslotOptions="timeslotOptions"
      @update:timeslot="updateTimeslot"
      @prev="handlePrev"
      @next="handleNext"
    />

    <div class="reservations-timeline__rows">
      <div class="reservations-timeline__background">
        <div class="reservations-timeline__sidebar" />
        <div class="reservations-timeline__unavailables">
          <TimelineUnavailable
            v-for="period in nonWorkingPeriods"
            :key="period.start"
            :time-window="timeWindow"
            :period="period"
          />
        </div>
      </div>

      <div
        v-for="(asset, index) in assetList"
        :key="asset.id"
        :ref="rowRef"
        :data-index="index"
        class="reservations-timeline__row"
      >
        <TimelineRow
          v-if="asset.isVisible"
          sidebar-class="reservations-timeline__sidebar"
          :name="asset.name"
          :reservations="assets.get(asset.id).reservations"
          :time-window="timeWindow"
          @select-item="$emit('select-item', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {debounce, throttle, isEqual} from "lodash-es";
import moment from "moment-timezone";
import NotifyMixin from "@/mixins/NotifyMixin";
import ReservationsToolbar from "@/components/ri/reservations/ReservationsToolbar";
import ReservationsRibbon from "@/components/ri/reservations/ReservationsRibbon";
import TimelineRow from "@/components/ri/reservations/TimelineRow";
import TimelineUnavailable from "@/components/ri/reservations/TimelineUnavailable";
import {mergeReservations} from "@/components/ri/reservations/timeline";
import AssetsUnavailableIcon from "@/components/ri/reservations/AssetsUnavailableIcon";
import AssetsAvailableIcon from "@/components/ri/reservations/AssetsAvailableIcon";
import AssetReservationEndsIcon from "@/components/ri/reservations/AssetReservationEndsIcon"
import AssetReservationStartsIcon from "@/components/ri/reservations/AssetReservationStartsIcon"
import {momentToLocalIso} from "@/components/ri/reservations/timeline";
import {initialTimeUnits, TIMESLOT} from "@/views/ri/constants";
import CommunityTimezoneMixin from "@/mixins/CommunityTimezoneMixin";
import {denormalizeScheduleByDays} from "@/utils/Schedule";

export default {
  components: {
    ReservationsToolbar,
    ReservationsRibbon,
    TimelineRow,
    TimelineUnavailable,
    AssetsUnavailableIcon,
    AssetsAvailableIcon,
    AssetReservationEndsIcon,
    AssetReservationStartsIcon,
  },

  mixins: [NotifyMixin, CommunityTimezoneMixin],

  props: {
    rentableItem: {
      required: true,
    },

    viewSettings: {
      required: true,
    },
  },

  emits: ['update:view-settings', 'select-item'],

  data() {
    return {
      modalContainer: null,
      weekdays: [],
      assetList: [],
      assets: new Map(),
      availabilityStats: {},
      availabilityNow: null,
      nonWorkingPeriods: [],
      intersectionObserver: null,
    };
  },

  created() {
    this.intersectionObserver = new IntersectionObserver(this.handleIntersections);

    if (!this.startDate) {
      this.resetStartDate();
    }
  },

  mounted() {
    this.modalContainer = document.getElementById('modal-body-container');
    this.modalContainer?.addEventListener?.('scroll', this.handleModalScroll);

    this.fetchWeekdays();
    this.fetchAssets()
      .then(() => {
        const {scrollTop} = this.viewSettings;

        if (this.modalContainer && scrollTop) {
          this.modalContainer.scrollTop = scrollTop;
        }
      });
    this.fetchAvailabilityStats();
    this.fetchAvailabilityNow();
  },

  unmounted() {
    this.modalContainer?.removeEventListener?.('scroll', this.handleModalScroll);
  },

  computed: {
    startDate() {
      const {startDate} = this.viewSettings;

      return startDate || this.resetStartDate();
    },

    numberOfPeriods() {
      switch (this.viewSettings.timeslot) {
        case TIMESLOT.MONTH:
          return 6;

        case TIMESLOT.WEEK:
          return 6;

        case TIMESLOT.DAY:
          return 7;

        case TIMESLOT.HOUR:
          return 24;

        default:
          return 1;
      }
    },

    step() {
      switch (this.viewSettings.timeslot) {
        case TIMESLOT.HOUR:
          return 6;

        default:
          return 1;
      }
    },

    timeslotOptions() {
      return initialTimeUnits.slice(0, initialTimeUnits.findIndex(o => o.key === this.rentableItem.timeslot.unit) + 1);
    },

    timeWindow() {
      const periods = Array.from({length: this.numberOfPeriods}, (_, index) => {
        const startDate = this.startDate.clone().add(index, this.viewSettings.timeslot);
        const lastDate = startDate.clone().endOf(this.viewSettings.timeslot);
        const endDate = startDate.clone().add(1, this.viewSettings.timeslot);

        return {
          startDate,
          lastDate,
          endDate,
        };
      });

      const {startDate} = periods.at(0);
      const {lastDate, endDate} = periods.at(-1);
      const durationMillis = endDate.diff(startDate);

      return {
        startDate,
        lastDate,
        endDate,
        durationMillis,
        periods,
      };
    },

    timeWindowLabel() {
      const {startDate} = this.timeWindow;

      switch (this.viewSettings.timeslot) {
        case TIMESLOT.MONTH:
          return this.formatDateTime(startDate, 'YYYY', true);

        case TIMESLOT.WEEK:
        case TIMESLOT.DAY:
          return this.formatDateTime(startDate, 'MMMM, YYYY', true);

        case TIMESLOT.HOUR:
          return this.formatDateTime(startDate, 'MMMM D, YYYY', true);

        default:
          return '';
      }
    },
  },

  watch: {
    timeWindow(value, oldValue) {
      if (
        isEqual(value.startDate?.toString?.(), oldValue.startDate?.toString?.()) &&
        isEqual(value.endDate?.toString?.(), oldValue.endDate?.toString?.())
      ) {
        return;
      }

      this.fetchVisibleReservations(true);
      this.fetchAvailabilityStats();
      this.calculateNonWorkingPeriods();
    },

    weekdays() {
      this.calculateNonWorkingPeriods();
    },
  },

  methods: {
    rowRef(el) {
      if (!el) {
        return;
      }

      this.intersectionObserver.observe(el);
    },

    updateTimeslot(timeslot) {
      this.$emit('update:view-settings', {
        mode: this.viewSettings.mode,
        timeslot,
      });
    },
    
    updateViewSettings(value) {
      const values = {
        ...this.viewSettings,
        ...value,
      };
      this.$emit('update:view-settings', values);
    },

    resetStartDate() {
      const startDate = this.viewSettings.timeslot === TIMESLOT.HOUR
        ? moment().startOf('day')
        : moment().startOf(this.viewSettings.timeslot).subtract(1, this.viewSettings.timeslot);

      this.updateViewSettings({
        startDate,
      });

      return startDate;
    },

    calculateNonWorkingPeriods() {
      const {timeslot} = this.viewSettings;

      if (timeslot === TIMESLOT.YEAR || timeslot === TIMESLOT.MONTH || timeslot === TIMESLOT.WEEK) {
        this.nonWorkingPeriods = [];
        return;
      }

      const schedule = denormalizeScheduleByDays(this.rentableItem.availabilitySchedule, this.weekdays);

      const nonWorkingPeriods = [];

      let periodStartDate = this.timeWindow.startDate.clone().subtract(1, 'week');
      const endDate = periodStartDate.clone().add(3, 'weeks');
      let periodEndDate = periodStartDate.clone();

      if (timeslot === TIMESLOT.DAY) {
        while (periodStartDate < endDate) {
          const nextEndDate = periodEndDate.clone().add(1, 'day');
          const daySchedule = schedule[periodEndDate.day()];

          if (daySchedule) {
            if (periodEndDate > periodStartDate) {
              nonWorkingPeriods.push({
                start: periodStartDate.valueOf(),
                end: periodEndDate.valueOf(),
              });
            }
            periodEndDate = nextEndDate;
            periodStartDate = periodEndDate.clone();
          } else {
            periodEndDate = nextEndDate;
          }
        }

        this.nonWorkingPeriods = nonWorkingPeriods;
      }

      if (timeslot === TIMESLOT.HOUR) {
        while (periodStartDate < endDate) {
          const daySchedule = schedule[periodEndDate.day()];

          if (daySchedule) {
            const date = periodEndDate.format('L ');

            daySchedule.forEach(period => {
              const open = moment(date + period.open, 'L LT');
              const close = moment(date + period.close, 'L LT');

              periodEndDate = open;

              if (periodEndDate > periodStartDate) {
                nonWorkingPeriods.push({
                  start: periodStartDate.valueOf(),
                  end: periodEndDate.valueOf(),
                });
              }

              periodStartDate = close;
              periodEndDate = periodStartDate.clone();
            });

            if (daySchedule[0].open === daySchedule[0].close) {
              periodStartDate.add(1, 'day');
            }
          }

          periodEndDate.add(1, 'day').startOf('day');
        }
      }

      this.nonWorkingPeriods = nonWorkingPeriods;
    },

    handlePrev() {
      this.navigateTimeline(1, -this.step);
    },

    handleNext() {
      this.navigateTimeline(1, this.step);
    },

    navigateTimeline: throttle(function (num, step = 1) {
      // sequential shift is a workaround to make neat transition animation
      if (num > 0) {
        this.$nextTick(() => {
          this.updateViewSettings({
            startDate: this.viewSettings.startDate.clone().add(step, this.viewSettings.timeslot),
          });
          this.navigateTimeline(num - 1, step);
        });
      }
    }, 500),

    handleModalScroll(event) {
      this.updateViewSettings({
        scrollTop: event.target.scrollTop,
      });
    },

    handleIntersections(entries) {
      entries.forEach(entry => {
        const {index} = entry.target.dataset;
        this.assetList[+index].isVisible = entry.isIntersecting;
      });

      this.fetchVisibleReservations();
    },

    async fetchAssets() {
      try {
        let result;
        let page = 0;

        do {
          result = await this.$riDataProvider.getAssets('timeline', {
            riId: this.rentableItem.id,
            page: ++page,
          });

          for (const asset of result.assets) {
            if (!this.assets.has(asset.id)) {
              this.assets.set(asset.id, {
                dtRange: null,
                isLoading: false,
                reservations: [],
              });
            }
          }

          this.assetList = [
            ...this.assetList,
            ...result.assets,
          ];
        } while (!result.last);
      } catch (error) {
        this.notifyError(error.message);
      }
    },

    async fetchWeekdays() {
      try {
        this.weekdays = await this.$riDataProvider.getList('weekdays');
      } catch (error) {
        this.notifyError(error.message);
      }
    },

    fetchVisibleReservations: debounce(async function (allVisible) {
      const dtRange = {
        start: momentToLocalIso(this.timeWindow.startDate),
        end: momentToLocalIso(this.timeWindow.endDate),
      };

      const assetsIds = this.assetList
        .filter(asset => {
          const assetData = this.assets.get(asset.id);
          if (!asset.isVisible) {
            return false;
          }

          if (allVisible) {
            return true;
          }

          return !isEqual(dtRange, assetData.dtRange) && !assetData.isLoading;
        })
        .map(asset => asset.id);

      if (assetsIds.length === 0) {
        return;
      }

      assetsIds.forEach(id => {
        this.assets.get(id).isLoading = true;
      });

      try {
        const reservations = await this.$riDataProvider.getReservations('timeline', {
          riId: this.rentableItem.id,
          data: {
            assetsIds,
            dtRange: {
              start: momentToLocalIso(this.timeWindow.startDate),
              end: momentToLocalIso(this.timeWindow.endDate),
            },
          },
        });

        for (const [id, assetReservations] of Object.entries(reservations)) {
          this.assets.set(id, {
            dtRange,
            isLoading: false,
            reservations: mergeReservations(this.assets.get(id).reservations, assetReservations, this.timeWindow),
          });
        }
      } catch (error) {
        this.notifyError(error.message);
      }
    }, 500),

    async fetchAvailabilityStats() {
      const {periods} = this.timeWindow;

      try {
        const stats = await this.$riDataProvider.availabilityStats('reservations', {
          riId: this.rentableItem.id,
          data: periods.map(period => ({
            start: momentToLocalIso(period.startDate),
            end: momentToLocalIso(period.endDate),
          })),
        });

        for (const [index, period] of periods.entries()) {
          this.availabilityStats[momentToLocalIso(period.startDate)] = stats[index];
        }
      } catch (error) {
        this.notifyError(error.message);
      }
    },

    async fetchAvailabilityNow() {
      const now = momentToLocalIso(moment());

      try {
        const stats = await this.$riDataProvider.availabilityStats('reservations', {
          riId: this.rentableItem.id,
          data: [
            {
              start: now,
              end: now,
            }
          ],
        });

        this.availabilityNow = stats[0];
      } catch (error) {
        this.notifyError(error.message);
      }
    },
  },
};
</script>

<style scoped>
.reservations-timeline {
  @apply min-h-full mr-2;

  &__ribbon {
    @apply sticky z-10;
    top: 60px;
  }

  /* purgecss start ignore */
  & :deep(&__sidebar) {
    @apply w-24 min-w-24;
  }
  /* purgecss end ignore */

  &__rows {
    @apply relative px-px;
  }

  &__row {
    @apply h-12;
  }

  &__background {
    @apply absolute flex w-full h-full border-transparent border-r;
  }

  &__unavailables {
    @apply relative flex-1 h-full;
  }
}
</style>
