<template>
  <div
    class="timeline-unavailable"
    :style="unavailableStyle"
  />
</template>

<script>
import {timelinePosition} from "@/components/ri/reservations/timeline";

export default {
  props: {
    timeWindow: {
      type: Object,
      required: true,
    },

    period: {
      type: Object,
      required: true,
    },
  },

  computed: {
    unavailableStyle() {
      let leftPosition = timelinePosition(this.timeWindow, this.period.start);
      if (leftPosition < 0) {
        leftPosition = 0;
      } else if (leftPosition > 100) {
        leftPosition = 100;
      }

      let rightPosition = 100 - timelinePosition(this.timeWindow, this.period.end);
      if (rightPosition < 0) {
        rightPosition = 0;
      } else if (rightPosition > 100) {
        rightPosition = 100;
      }

      return {
        left: `${leftPosition}%`,
        right: `${rightPosition}%`,
      };
    },
  },
};
</script>

<style scoped>
.timeline-unavailable {
  @apply absolute top-0 bottom-0;
  @apply transition-all duration-500;

  background: repeating-linear-gradient(
    -45deg,
    #FCFCFC,
    #FCFCFC 6px,
    #F4F4F4 6px,
    #F4F4F4 12px
  );
}
</style>
