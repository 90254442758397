<template>
  <div class="flex">
    <div class="relative mr-3">
      <DatePicker
        class="datepicker"
        :placeholder="datePlaceholder"
        :value="dateValue"
        @change="handleDateChange"
        @updateMonthYear="(val) => $emit('updateMonthYear', val)"
        @clear="clearDate"
        @close="$emit('date-close')"
        v-bind="{
          disabled,
          minDate,
          maxDate,
          allowedDates,
          format,
          monthYear,
          monthNameFormat,
        }"
        clearable
        autoApply
      />
    </div>

    <TimeInput
      ref="timeInput"
      :model-value="timeValue"
      @update:modelValue="handleTimeChange"
      @cleared="clearTime"
      :placeholder="timePlaceholder"
      :disabled="timeDisabled || disabled"
      :options="options"
      :readOnly="!timeEditMode"
      :inputEditable="timeInputEditable"
    />
  </div>
</template>

<script>
import DatePicker from "@/components/ui/datepicker/DatePicker";
import TimeInput from "@/components/ui/TimeInput";
import CommunityTimezoneMixin from "@/mixins/CommunityTimezoneMixin";
import moment from "moment-timezone";

export default {
  components: { TimeInput, DatePicker },

  mixins: [CommunityTimezoneMixin],

  model: {
    prop: "modelValue",
    event: "update:modelValue",
  },

  props: {
    /**
     * component's v-model
     */
    modelValue: {
      required: false,
      default: null,
    },

    /**
     * Disabled state
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Time input edit mode
     */
    timeEditMode: {
      type: Boolean,
      default: true,
    },

    /**
     * All dates before the given date will be disabled
     */
    minDate: {
      type: String,
      default: null,
    },

    /**
     * All dates after the given date will be disabled
     */
    maxDate: {
      type: String,
      default: null,
    },

    /**
     * Allow only specific dates
     */
    allowedDates: {
      type: Array,
      default: () => [],
    },
    /**
     * Format the value of the date(s) in the input field
     */
    format: {
      type: String,
      default: "MM/DD/YYYY",
    },
    /**
     * Formats value before saving to the form state
     */
    formatter: {
      type: Function,
      default: (value) => value,
    },
    /**
     * Date in community timezone
     */
    inCommunityTimezone: {
      type: Boolean,
      default: true,
    },

    monthYear: {
      type: Function,
    },
    monthNameFormat: {
      type: String,
      default: "short",
    },

    /**
     * Placeholder text for date input
     */
    datePlaceholder: {
      type: String,
      default: "Date",
    },

    /**
     * Placeholder text for time input
     */
    timePlaceholder: {
      type: String,
      required: false,
    },
    /**
     * Add options dropdown
     */
    options: {
      type: Array,
      required: false,
    },
    /**
     * Disable time input
     */
    timeDisabled: {
      type: Boolean,
    },
    /**
     * Allows time input typing
     */
    timeInputEditable: {
      type: Boolean,
      default: true,
    },
  },

  emits: [
    "update:modelValue",
    "updateMonthYear",
    "date-close",
    "date-clear",
    "updateDate",
  ],

  data() {
    return {
      dateValue: undefined,
      timeValue: undefined,
    };
  },

  computed: {
    isValueComplete() {
      return this.dateValue && this.timeValue;
    },
    isEmpty() {
      return !this.dateValue && !this.timeValue;
    },
  },

  watch: {
    modelValue: {
      handler(value) {
        if (!value) {
          return;
        }

        const momentObject = this.inCommunityTimezone
          ? this.parseDateTime(value)
          : moment.utc(value);

        if (!momentObject.isValid()) {
          if (!this.isEmpty) {
            this.clear();
          }

          return;
        }

        this.timeValue = {
          hours: momentObject.hours(),
          minutes: momentObject.minutes(),
        };
        this.dateValue = momentObject.startOf("day");
      },
      immediate: true,
    },
  },

  methods: {
    handleDateChange(value) {
      if (this.inCommunityTimezone) {
        this.dateValue = moment(value).startOf("day");
      } else {
        const formattedValue = moment(value).format("YYYY-MM-DDTHH:mm:ss");
        this.dateValue = moment.utc(formattedValue).startOf("day");
      }

      this.$emit("updateDate", this.dateValue.format("YYYY-MM-DD"));

      this.emitValue();
    },

    handleTimeChange(value) {
      this.timeValue = value;

      this.emitValue();
    },

    emitValue() {
      if (!this.isValueComplete) {
        this.$emit("update:modelValue", null);
      } else {
        const dateTime = this.dateValue
          .hour(this.timeValue.hours)
          .minute(this.timeValue.minutes);

        const parsedDateTime = this.inCommunityTimezone
          ? this.parseDateTime(dateTime, true).toISOString()
          : dateTime.toISOString();

        const formattedDateTime = this.formatter(parsedDateTime);

        this.$emit("update:modelValue", formattedDateTime);
      }
    },

    clear() {
      this.clearDate();
      this.clearTime();
    },

    clearDate() {
      this.dateValue = undefined;
      this.$emit("updateDate", null);
      this.emitValue();
    },

    clearTime() {
      this.timeValue = undefined;
    },
  },
};
</script>

<style scoped>
.datepicker:deep(input.form-input) {
  @apply pr-10;
}
.calendar-icon {
  @apply absolute w-4 h-4 text-graphite-800;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
