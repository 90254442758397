<template>
  <div class="flex flex-col h-full">
    <div class="flex justify-between font-frank font-bold text-sm text-black mb-2">
      <div>{{itemsCount}} non-fungible {{$tc('ri.asset', itemsCount)}}</div>
      <button
        v-if="hasPermission('RI_EDIT') && !deactivated"
        class="btn-action"
        type="button"
        :disabled="adding"
        @click.stop="adding = true"
      >
        <Icon class="w-4 h-4 mr-1" name="plus"/>add new
      </button>
    </div>

    <div
      v-if="!deactivated && selected.length === itemsCount"
      class="flex justify-center bg-red-100 border border-red-300 text-red-500 font-medium px-2 py-1"
    >
      <InfoText class="h-6 text-2sm">you cannot deactivate or delete all non-fungible assets</InfoText>
    </div>
    <div
      v-else-if="infoSelectedActions"
      class="flex justify-between bg-active-50 border border-active-200 text-active-800 px-2 py-1"
    >
      <div/>
      <InfoText class="text-2sm">to manage the selected {{selected.length}} {{$tc('ri.asset', selected.length)}} please use the main 3-dot menu</InfoText>
      <IconButton icon="close" class="text-graphite-800" @click="handleCloseInfo"/>
    </div>

    <InfiniteTable
      ref="table"
      class="assets-table mt-1 h-full"
      :dataProvider="$riDataProvider"
      :additionalRequestParams="{riId, status}"
      resource="assets"
      resourceLabel="assets"
      :columns="columns"
      :max-items="Infinity"
      @initialized="handleTableInitialized"
    >
      <template v-slot:add v-if="adding">
        <FinalForm :submit="handleAddAsset">
          <template v-slot="props">
            <form
              class="flex items-center p-4 bg-active-50"
              @submit="props.handleSubmit"
            >
              <div class="w-1/12" />
              <div class="w-5/12">
                <TextField
                  ref="add"
                  name="name"
                  size="small"
                  maxlength="150"
                  :validate="composeValidators(required, minLength(1), maxLength(150))"
                />
              </div>
              <div class="w-4/12" />
              <div class="flex w-2/12">
                <IconButton
                  type="reset"
                  icon="trash"
                  size="big"
                  class="flex-shrink-0 text-red-900"
                  @click="adding = false"
                />
                <button
                  type="submit"
                  class="btn btn-small btn-primary btn-solid ml-2"
                  :disabled="props.invalid && !props.dirtySinceLastSubmit"
                >
                  add
                </button>
              </div>
            </form>
          </template>
        </FinalForm>
      </template>
      <template v-slot:column:select="{item}">
        <input type="checkbox" class="block" v-model="selected" :value="item.id"/>
      </template>
      <template v-slot:column:name="{item}">
        <EditableField
          class="w-full"
          edit-class="cell-edit"
          :with-edit-control="hasPermission('RI_EDIT')"
          :initial-values="item"
          :on-submit="handleChangeName"
        >
          <template v-slot:field="{editMode}">
            <TextField
              name="name"
              size="small"
              text-class="py-4"
              :edit-mode="editMode"
              maxlength="150"
              :validate="composeValidators(required, minLength(1), maxLength(150))"
            />
          </template>
        </EditableField>
      </template>
      <template v-slot:column:actions="{item}">
        <ListActions v-if="hasPermission('RI_EDIT') && (deactivated || itemsCount > 1)" icon-class="row-hover--visible">
          <!-- <button
            v-if="deactivated"
            @click="handleAction('activate', item)"
            type="button"
            class="menu-action"
          >
            <Icon name="restore" class="w-6 h-6 p-1 mr-1"/>Restore
          </button>
          <button
            v-else
            @click="handleAction('deactivate', item)"
            type="button"
            class="menu-action"
          >
            <Icon name="clock" class="w-6 h-6 p-1 mr-1"/>Temporarily deactivate
          </button> -->
          <button
            @click="handleAction('bulkDelete', item)"
            type="button"
            class="menu-action text-red-900"
          >
            <Icon name="trash" class="w-6 h-6 p-1 mr-1"/>Delete
          </button>
        </ListActions>
      </template>
    </InfiniteTable>
    <Loader :loading="loading" backdrop />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {FinalForm} from "vue-final-form";
import ModalNavigation from "@/mixins/ModalNavigation";
import ActionsMixin from "@/mixins/ActionsMixin";
import ConfirmationMixin from "@/mixins/ConfirmationMixin";
import ValidatorMixin from "@/components/form/ValidatorMixin";
import InfiniteTable from "@/components/list/InfiniteTable";
import TextField from "@/components/form/TextField";
import EditableField from "@/components/ui/inlineediting/EditableField";
import ListActions from "@/components/auth/list/ListActions";
import Icon from "@/components/ui/Icon";
import InfoText from "@/components/ui/InfoText";
import IconButton from "@/components/ui/IconButton";
import Loader from "@/components/ui/Loader";
import NotifyMixin from "@/mixins/NotifyMixin";

const columns = [
  {
    name: 'select',
    title: '',
    class: 'w-1/12',
  },
  {
    name: 'name',
    title: 'asset name',
    class: 'w-6/12',
  },
  {
    name: 'reservationsCount',
    title: 'active & pending reservations',
    class: 'w-3/12 whitespace-no-wrap',
  },
  {
    name: 'actions',
    title: 'actions',
    class: 'w-2/12 flex justify-end',
  },
];

const actions = {
  deactivate: (assetsLabel, reservationsLabel) => {
    const params = {
      id: 'deactivate-assets',
      title: 'temporarily deactivate',
      showConfirmation: true,
      confirmationMessage: `are you sure you want to temporarily deactivate ${assetsLabel}?`,
      confirmBtnText: 'yes, deactivate',
      cancelBtnText: 'no, go back',
      confirmationType: 'success',
      notificationMessage: `${assetsLabel} temporarily deactivated`,
    };

    if(reservationsLabel) {
      params.confirmationNote = `As a result ${reservationsLabel} will be canceled. This action can’t be undone.`
    }

    return params;
  },

  activate: name => ({
    id: 'restore-assets',
    title: 'restore',
    showConfirmation: true,
    confirmationMessage: `are you sure you want to restore ${name}?`,
    confirmBtnText: 'yes, restore',
    cancelBtnText: 'no, go back',
    confirmationType: 'success',
    notificationMessage: `${name} restored`,
  }),

  bulkDelete: (assetsLabel, reservationsLabel) => {
    const params = {
      id: 'delete-assets',
      title: 'delete',
      showConfirmation: true,
      confirmationMessage: `are you sure you want to delete ${assetsLabel}?`,
      confirmBtnText: 'yes, delete',
      cancelBtnText: 'no, go back',
      confirmationType: 'warning',
      notificationMessage: `${assetsLabel} deleted`,
    }

    if(reservationsLabel) {
      params.confirmationNote = `As a result ${reservationsLabel} will be canceled. This action can’t be undone.`
    }

    return params;
  },
};

export default {
  components: {
    InfiniteTable,
    FinalForm,
    TextField,
    EditableField,
    ListActions,
    Icon,
    InfoText,
    IconButton,
    Loader,
  },

  mixins: [
    ModalNavigation,
    ActionsMixin,
    ConfirmationMixin,
    ValidatorMixin,
    NotifyMixin,
  ],

  props: {
    status: {
      type: String,
      default: 'ACTIVE',
    },
  },

  setup() {
    return {
      columns,
      actions,
    };
  },

  data() {
    return {
      itemsCount: 0,
      loading: false,
      editing: null,
      adding: false,
      selected: [],
      infoSelectedActions: false,
    };
  },

  computed: {
    ...mapGetters({
      hasPermission: 'ri/hasPermission',
    }),

    riId() {
      return this.$route.params.rentableItemId;
    },

    deactivated() {
      return this.status === 'DEACTIVATED';
    },
  },

  watch: {
    selected(newValue, oldValue) {
      this.removeConfirmAction(this.actions.activate().id);
      this.removeConfirmAction(this.actions.deactivate().id);
      this.removeConfirmAction(this.actions.bulkDelete().id);

      if (oldValue.length === 0) {
        this.infoSelectedActions = true;
      }

      if (newValue.length === 0) {
        this.infoSelectedActions = false;
      }

      if (this.hasPermission('RI_EDIT') && this.selected.length > 0) {
        // if (this.deactivated) {
        //   const name = this.getSelectionName();
        //   this.addConfirmAction(this.actions.activate(name), this.handleBulkActivate);
        // } else if (this.itemsCount > this.selected.length) {
        //   this.addConfirmAction(this.actions.deactivate(...this.getAssetsRemoveParams(this.selected)), this.handleBulkDeactivate);
        // }

        if (this.deactivated || this.itemsCount > this.selected.length) {
          this.addConfirmAction(this.actions.bulkDelete(...this.getAssetsRemoveParams(this.selected)), this.handleBulkDelete);
        }
      }
    },

    adding(value) {
      this.$nextTick(() => {
        if (value) {
          this.$refs.add.focus();
        }
      });
    },
  },

  methods: {
    getSelectionName() {
      const count = this.selected.length;

      if (count > 1) {
        return `${count} assets`;
      } else {
        const items = this.$refs.table.getItems();
        const item = items.find(item => item.value.id === this.selected[0]);
        return `asset ${item.value.name}`;
      }
    },

    getAssetsRemoveParams(assetsIds = []){
      const items = this.$refs.table.getItems() || [];
      const selectedAssetsCount = assetsIds.length;
      const assetsReservationsCount = items.reduce((acc, {value: {id, reservationsCount}}) => {
        if(assetsIds.includes(id)){
          return acc + reservationsCount;
        }

        return acc;
      }, 0);
      const assetsLabel= `${selectedAssetsCount} ${this.$tc('ri.asset', selectedAssetsCount)}`;
      const reservationsLabel = assetsReservationsCount ? `${assetsReservationsCount} ${this.$tc('ri.reservation', assetsReservationsCount)}` : null;

      return [assetsLabel, reservationsLabel];
    },

    handleTableInitialized(items, count) {
      this.itemsCount = count;
    },

    handleCloseInfo() {
      this.infoSelectedActions = false;
    },

    async doBulkAction(actionKey, action, selected = this.selected) {
      this.loading = true;

      try {
        await this.$riDataProvider[actionKey]('assets', {
          riId: this.riId,
          data: selected,
        });

        const toRemove = new Set(selected);
        this.$refs.table.modifyItems(items => items.filter(item => !toRemove.has(item.value.id)));
        this.itemsCount -= selected.length;
        this.selected = [];
        this.notifySuccess(action.notificationMessage);
      } catch (error) {
        return {name: error.message};
      } finally {
        this.loading = false;
      }
    },

    handleBulkActivate(action) {
      this.doBulkAction('activate', action);
    },

    handleBulkDeactivate(action) {
      this.doBulkAction('deactivate', action);
    },

    handleBulkDelete(action) {
      this.doBulkAction('bulkDelete', action);
    },

    handleAction(actionKey, item) {
      const actionParams = ['bulkDelete', 'deactivate'].includes(actionKey) ? this.getAssetsRemoveParams([item.id]) : [`asset ${item.name}`]
      const action = this.actions[actionKey](...actionParams);

      this.requestConfirmation(action)
        .then(result => {
          if (result) {
            this.doBulkAction(actionKey, action, [item.id]);
          }
        });
    },

    async handleAddAsset(values) {
      this.loading = true;

      try {
        await this.$riDataProvider.nameCheck('assets', {
          riId: this.riId,
          data: {
            name: values.name,
          },
        });
      } catch (error) {
        return {name: error.message};
      } finally {
        this.loading = false;
      }

      try {
        const savedAsset = await this.$riDataProvider.create('assets', {
          riId: this.riId,
          data: {
            name: values.name,
          },
        });

        this.$refs.table.modifyItems(items => {
          return [
            {
              token: '__',
              value: savedAsset,
            },
            ...items,
          ];
        });

        setTimeout(() => {
          this.$refs.table.scrollToItem(savedAsset.id);
        });

        this.itemsCount += 1;
        this.adding = false;
      } catch (error) {
        this.notifyError(error.message);
      } finally {
        this.loading = false;
      }
    },

    async handleChangeName(asset) {
      this.loading = true;

      try {
        await this.$riDataProvider.nameCheck('assets', {
          riId: this.riId,
          data: {
            name: asset.name,
          },
        });
      } catch (error) {
        return {name: error.message};
      } finally {
        this.loading = false;
      }

      try {
        const savedAsset = await this.$riDataProvider.update('assets', {
          riId: this.riId,
          id: asset.id,
          data: {
            name: asset.name,
          },
        });

        this.$refs.table.modifyItems(items => {
          const item = items.find(item => item.value.id === savedAsset.id);
          if (item) {
            item.value = savedAsset;
          }
        });
      } catch (error) {
        this.notifyError(error.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.assets-table :deep(.body > .row) {
  min-height: 49px;
}

.assets-table :deep(.body > .row > .column) {
  @apply py-0;
}

.menu-action {
  @apply flex px-2 w-full items-center;
  height: 2.25rem;

  &:hover {
    @apply bg-active-100;
  }
}

.assets-table :deep(.cell-edit) {
  @apply flex;
}
</style>
