<template>
  <div
    v-bind:key="dayIndex"
    v-for="dayIndex in Object.keys(schedule)"
    class="tooltip__row"
  >
    <div class="tooltip__day">
      {{ days[dayIndex] }}
    </div>
    <div class="tooltip__times">
      <div
        v-bind:key="index"
        v-for="(dayScheduleItem, index) in schedule[dayIndex]"
        class="tooltip__time"
      >
        {{ getScheduleLabel(dayScheduleItem) }}
      </div>
    </div>
  </div>
</template>

<script>
import {getTimeStringFromObject} from "@/utils/Schedule";

export default {
  props: {
    schedule: {
      type: Object,
      required: true,
    },
    days: {
      type: Array,
      required: true,
    },
  },

  methods: {
    getScheduleLabel(dayScheduleItem){
      return dayScheduleItem.closed ?
        'Closed'
        : `${getTimeStringFromObject(dayScheduleItem.openTime)} – ${getTimeStringFromObject(dayScheduleItem.closeTime)}`;
    },
  }
}
</script>

<style scoped>
.tooltip__row {
  @apply flex mt-3;

  &:first-child {
    @apply mt-0;
  }
}

.tooltip__day {
  @apply w-5/12 pr-3 text-2sm font-semibold font-inter;
}

.tooltip__times {
  @apply w-7/12;
}

.tooltip__time {
  @apply font-inter text-2sm mt-1;

  &:first-child {
    @apply mt-0;
  }
}
</style>
