<template>
  <div
    class="timeline-reservation"
    :class="reservationClass"
    :style="reservationStyle"
    @click="$emit('click')"
  >
    <template v-if="reservation.status === 'PENDING'">
      <ReservationArrowLeftOutlineIcon
          v-if="leftOverflow"
          class="timeline-reservation__overflow timeline-reservation__overflow--left"
      />
      <ReservationArrowRightOutlineIcon
          v-if="rightOverflow"
          class="timeline-reservation__overflow timeline-reservation__overflow--right"
      />
    </template>
    <template v-else>
      <ReservationArrowLeftIcon
          v-if="leftOverflow"
          class="timeline-reservation__overflow timeline-reservation__overflow--left"
      />
      <ReservationArrowRightIcon
          v-if="rightOverflow"
          class="timeline-reservation__overflow timeline-reservation__overflow--right"
      />
    </template>
    <div class="timeline-reservation__profile timeline-reservation__profile--address">{{profileAddress}}</div>
    <div class="timeline-reservation__profile timeline-reservation__profile--person">{{profilePerson}}</div>
  </div>
</template>

<script>
import ReservationArrowLeftIcon from "@/components/ri/reservations/ReservationArrowLeftIcon";
import ReservationArrowLeftOutlineIcon from "@/components/ri/reservations/ReservationArrowLeftOutlineIcon";
import ReservationArrowRightIcon from "@/components/ri/reservations/ReservationArrowRightIcon";
import ReservationArrowRightOutlineIcon from "@/components/ri/reservations/ReservationArrowRightOutlineIcon";
import {timelinePosition} from "@/components/ri/reservations/timeline";

export default {
  components: {ReservationArrowLeftIcon, ReservationArrowLeftOutlineIcon, ReservationArrowRightIcon, ReservationArrowRightOutlineIcon},

  props: {
    reservation: {
      type: Object,
      required: true,
    },

    timeWindow: {
      type: Object,
      required: true,
    },
  },

  emits: ['click'],

  computed: {
    leftPosition() {
      return timelinePosition(this.timeWindow, this.reservation.start);
    },

    rightPosition() {
      return 100 - timelinePosition(this.timeWindow, this.reservation.end);
    },

    visible() {
      return (this.leftPosition >= 0 && this.leftPosition <= 100)
          || (this.rightPosition >= 0 && this.rightPosition <= 100)
          || (this.leftPosition < 0 && this.rightPosition < 0);
    },

    leftOverflow() {
      return this.visible && this.leftPosition < 0;
    },

    rightOverflow() {
      return this.visible && this.rightPosition < 0;
    },

    reservationClass() {
      return `timeline-reservation--${this.reservation.status.toLowerCase()}`;
    },

    reservationStyle() {
      let left;
      if (this.leftOverflow) {
        left = '11px';
      } else if (this.visible && this.leftPosition < 2) {
        left = '2px';
      } else {
        left = `${this.leftPosition}%`;
      }

      let right;
      if (this.rightOverflow) {
        right = '11px';
      } else if (this.visible && this.rightPosition < 2) {
        right = '2px';
      } else {
        right = `${this.rightPosition}%`;
      }

      return {
        left,
        right,
      };
    },

    profileAddress() {
      const {profile} = this.reservation;

      return this.$tc(
        profile.unitId === profile.apartmentId
          ? 'ri.unit_building'
          : 'ri.unit_apartment_building',
        profile
      );
    },

    profilePerson() {
      const {profile} = this.reservation;

      return `${profile.firstName} ${profile.lastName}`;
    },
  },
};
</script>

<style scoped>
.timeline-reservation {
  @apply absolute h-10 border rounded cursor-pointer;
  @apply border-active-500 bg-active-500 text-white;
  @apply transition-all duration-500;

  padding: 0.5rem 0.625rem;
  top: 0.25rem;

  &:hover {
    @apply bg-active-600 text-white;
  }

  &--pending {
    @apply text-active-500 bg-active-50;

    &:hover {
      @apply border-active-600 text-active-600 bg-active-50;
    }
  }

  &__profile {
    @apply text-3xs leading-3 truncate;

    &--address {
      @apply font-semibold;
    }

    &--person {
      opacity: 0.7;

      .timeline-reservation--pending & {
        opacity: 1;
      }
    }
  }

  &__overflow {
    @apply absolute text-active-500;
    top: 4px;

    .timeline-reservation:hover & {
      @apply text-active-600;
    }

    &--left {
      left: -9px;
    }

    &--right {
      right: -9px;
    }
  }
}
</style>
