<template>
  <div
    class="timeline-period-availability"
    :class="{'timeline-period-availability--grid': !tiny}"
  >
    <template v-if="tiny">
      <AvailabilityIndicator
        class="timeline-period-availability__item"
        :value="availability"
      />
    </template>
    <template v-else>
      <div class="timeline-period-availability__item">
        <AssetsUnavailableIcon class="timeline-period-availability__icon"/>
        <AmountInput read-only :precision="0" :value="stats.unavailable"/>
      </div>
      <div class="timeline-period-availability__item">
        <AssetReservationEndsIcon class="timeline-period-availability__icon"/>
        <AmountInput read-only :precision="0" :value="stats.reservationEnds"/>
      </div>
      <div class="timeline-period-availability__item">
        <AssetsAvailableIcon class="timeline-period-availability__icon"/>
        <AmountInput read-only :precision="0" :value="stats.available"/>
      </div>
      <div class="timeline-period-availability__item">
        <AssetReservationStartsIcon class="timeline-period-availability__icon"/>
        <AmountInput read-only :precision="0" :value="stats.reservationStarts"/>
      </div>
    </template>
  </div>
</template>

<script>
import AssetsUnavailableIcon from "@/components/ri/reservations/AssetsUnavailableIcon";
import AssetReservationEndsIcon from "@/components/ri/reservations/AssetReservationEndsIcon";
import AssetsAvailableIcon from "@/components/ri/reservations/AssetsAvailableIcon";
import AssetReservationStartsIcon from "@/components/ri/reservations/AssetReservationStartsIcon";
import AmountInput from "@/components/ui/AmountInput";
import AvailabilityIndicator from "@/components/ui/AvailabilityIndicator";

export default {
  components: {
    AvailabilityIndicator,
    AmountInput, AssetReservationStartsIcon, AssetsAvailableIcon, AssetReservationEndsIcon, AssetsUnavailableIcon},

  props: {
    stats: {
      type: Object,
      required: true,
    },

    tiny: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    availability() {
      const {unavailable, available, reservationStarts, reservationEnds} = this.stats;
      const total = unavailable + available + reservationStarts + reservationEnds;
      return (unavailable + 0.5 * reservationStarts + 0.5 * reservationEnds) / total;
    },
  },
};
</script>

<style scoped>
.timeline-period-availability {
  &--grid {
    @apply grid gap-x-3 gap-y-1 text-black text-3xs leading-3;
    grid-template-columns: auto auto;
  }

  &__item {
    @apply flex items-center;
  }

  &__icon {
    @apply mr-1;
  }
}
</style>
