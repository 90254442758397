<template>
  <div class="flex items-center font-medium" :class="content.class">
    <Icon :name="content.icon" class="w-4 mr-1 text-active-500" /> {{content.text}}
  </div>
</template>

<script>
import Icon from "@/components/ui/Icon";

export default {
  components: { Icon },

  props: {
    status: {
      type: String,
      required: true,
    },
  },

  computed: {
    content() {
      switch (this.status) {
        case 'PENDING':
          return {
            icon: 'pending2',
            text: 'Pending',
          };

        case 'ACTIVE':
          return {
            icon: 'active',
            text: 'Active',
          };

        case 'COMPLETED':
          return {
            icon: 'completed2',
            text: 'Completed',
            class: 'text-graphite-900',
          };

        case 'CANCELED':
          return {
            icon: 'canceled2',
            text: 'Canceled',
          };

        default:
          return {
            icon: 'dot',
            text: 'Unknown',
          };
      }
    },
  },
};
</script>
