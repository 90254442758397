<template>
  <div
    class="timeline-today"
    :class="todayClass"
    :style="todayStyle"
  >
    <div
      v-if="text"
      class="timeline-today__text"
    >
      {{text}}
    </div>
  </div>
</template>

<script>
import {timelinePosition} from "@/components/ri/reservations/timeline";
import CommunityTimezoneMixin from "@/mixins/CommunityTimezoneMixin";

export default {
  mixins: [CommunityTimezoneMixin],

  props: {
    timeWindow: {
      type: Object,
      required: true,
    },

    text: {
      type: String,
      default: null,
    },

    pointer: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    leftPosition() {
      return timelinePosition(this.timeWindow, this.formatDateTime());
    },

    visible() {
      return this.leftPosition >= 0 && this.leftPosition <= 100;
    },

    todayClass() {
      return this.pointer
        ? 'timeline-today--pointer'
        : 'timeline-today--line';
    },

    todayStyle() {
      return {
        left: `${this.leftPosition}%`,
        opacity: this.visible ? 1 : 0,
      };
    },
  },
};
</script>

<style scoped>
.timeline-today {
  @apply absolute opacity-100 transition-all duration-500;

  &--line {
    @apply top-0 h-full border-l border-black;
  }

  &--pointer {
    @apply top-0;

    &:after {
      content: "";
      @apply absolute transform rotate-45 border border-black;
      border-top-color: transparent;
      border-left-color: transparent;
      border-width: 4.5px;
      top: -7px;
      left: -4px;
    }
  }

  &-enter-from,
  &-leave-to {
    @apply opacity-0;
  }

  &__text {
    @apply text-3xs leading-3 font-semibold tracking-wider text-black whitespace-no-wrap;
    @apply transform -translate-x-1/2 -translate-y-4;
  }
}
</style>
